import {
  BenefitsContainer,
  KeyBenefits,
  RowHeader,
  WrapBox,
  BoxBennefit,
  Row2ndBenefits,
  Row1stBenefits,
  BenefitCarouselWrap,
  BenefitCarousel,
} from "./styled";
import TitleSection from "../../Common/TitleSection";
import { WrapParent } from "../../../Layout/Dashboard/styled";
import { Contents } from "../Banner/styled";
import { useContext, useState } from "react";
import { ContextProviderWrapper } from "../../Context";
import { Swiper, SwiperSlide } from "swiper/react";
import { SwiperButtonNext } from "../../Common/Button/BtnSwiper";

export default function Benefits() {
  const [slideActive, setSlideActive] = useState(0);

  const { isMobile } = useContext(ContextProviderWrapper)!;

  const handleNextSwiper = (swiper: any) => {
    const slideTo =
      slideActive === BOXS_ROW_2ND.length - 1 ? 0 : slideActive + 1;
    swiper.slideTo(slideTo);
  };

  const handlePreSwiper = (swiper: any) => {
    const slideTo =
      slideActive === 0 ? BOXS_ROW_2ND.length - 1 : slideActive - 1;
    swiper.slideTo(slideTo);
  };

  const onSetSwiper = (swiper: any) => {
    setSlideActive(swiper.activeIndex);
  };
  return (
    <BenefitsContainer id="Benefit">
      <RowHeader className="row-left">
        <div data-aos="fade-right" className="img-row row-center">
          <img src="/assets/images/landing/img-row.webp?v=1" alt="" />
        </div>
        <p data-aos="fade-left">
          iTON empower users <span>to grow</span> their balance through our
          ecosystem's diverse earning options, <span>including optimized</span>{" "}
          trading, staking, and farming opportunities
        </p>
        {!isMobile && (
          <div className="img-row-sm row-center">
            <img src="/assets/images/landing/img-line-sm.webp?v=1" alt="" />
          </div>
        )}
      </RowHeader>
      <KeyBenefits>
        <WrapParent>
          <Row1stBenefits className="row-between">
            <div data-aos="fade-right">
              <TitleSection
                pageName="Our Services"
                textLight="Key Benefits"
                textBold="From Us"
              />
            </div>

            <WrapBox className="row-center">
              <div className="img-box-gif row-center">
                <img
                  data-aos="fade-up"
                  src="/assets/images/landing/box-gif.gif"
                  alt="gif"
                />
              </div>
            </WrapBox>
            {isMobile && (
              <BenefitCarouselWrap data-aos="fade-left">
                <BenefitCarousel>
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={20}
                    loop={true}
                    autoplay={{
                      delay: 3000,
                    }}
                    pagination={{
                      clickable: false,
                    }}
                    onSlideChange={(swiper: any) => onSetSwiper(swiper)}
                  >
                    <SwiperSlide>
                      <BoxBennefit clipPath="polygon(10% 0, 100% 0, 100% 100%, 0 100%, 0 10%);">
                        <div className="box-benefit ">
                          <h1>
                            Transaction <br /> Optimization
                          </h1>
                          <Contents>
                            <div className="img-cornner">
                              <img
                                src="/assets/images/icon-corner-white.webp?v=1"
                                alt=""
                              />
                            </div>
                            <p>
                              iTON helps users compare prices from
                              <br /> multiple DEXs, ensuring transactions at
                              <br /> the best price and minimizing costs.
                            </p>
                          </Contents>
                          <div className="row-right">
                            <div className="img-corner-right row-center">
                              <img
                                src="/assets/images/landing/img-be-1.webp?v=1"
                                alt="be"
                              />
                            </div>
                          </div>
                        </div>
                      </BoxBennefit>
                    </SwiperSlide>
                    {BOXS_ROW_2ND.map((item: any, index: number) => (
                      <SwiperSlide>
                        <BoxBennefit clipPath="polygon(10% 0, 100% 0, 100% 100%, 0 100%, 0 10%);">
                          <div className="box-benefit">
                            <h1
                              dangerouslySetInnerHTML={{ __html: item.title }}
                            ></h1>
                            <Contents>
                              <div className="img-cornner">
                                <img
                                  src="/assets/images/icon-corner-white.webp?v=1"
                                  alt=""
                                />
                              </div>
                              <p
                                dangerouslySetInnerHTML={{ __html: item.des }}
                              ></p>
                            </Contents>
                            <div className="row-right">
                              <div
                                className="img-corner-right row-center"
                                style={{
                                  maxWidth: index === 0 ? "150px" : "180px",
                                }}
                              >
                                <img src={item.img} alt="be" />
                              </div>
                            </div>
                          </div>
                        </BoxBennefit>
                      </SwiperSlide>
                    ))}
                    {isMobile && (
                      <div
                        style={{
                          marginTop: "40px",
                        }}
                        className="row-right"
                      >
                        <SwiperButtonNext
                          onNext={handleNextSwiper}
                          onPre={handlePreSwiper}
                        />
                      </div>
                    )}
                  </Swiper>
                </BenefitCarousel>
              </BenefitCarouselWrap>
            )}
            {!isMobile && (
              <BoxBennefit
                data-aos="fade-left"
                clipPath="polygon(10% 0, 100% 0, 100% 100%, 0 100%, 0 10%);"
              >
                <div className="box-benefit ">
                  <h1>
                    Transaction <br /> Optimization
                  </h1>
                  <Contents>
                    <div className="img-cornner">
                      <img
                        src="/assets/images/icon-corner-white.webp?v=1"
                        alt=""
                      />
                    </div>
                    <p>
                      iTON helps users compare prices from <br />
                      multiple DEXs, ensuring transactions at
                      <br /> the best price and minimizing costs.
                    </p>
                  </Contents>
                  <div className="row-right">
                    <div className="img-corner-right row-center">
                      <img
                        src="/assets/images/landing/img-be-1.webp?v=1"
                        alt="be"
                      />
                    </div>
                  </div>
                </div>
              </BoxBennefit>
            )}
          </Row1stBenefits>
        </WrapParent>
        {!isMobile && (
          <WrapParent className="row-center ">
            <Row2ndBenefits className="row-center">
              {BOXS_ROW_2ND.map((item: any, index: number) => (
                <BoxBennefit data-aos="flip-left" clipPath={item.clipPath}>
                  <div className="box-benefit">
                    <h1 dangerouslySetInnerHTML={{ __html: item.title }}></h1>
                    <Contents>
                      <div className="img-cornner">
                        <img
                          src="/assets/images/icon-corner-white.webp?v=1"
                          alt=""
                        />
                      </div>
                      <p dangerouslySetInnerHTML={{ __html: item.des }}></p>
                    </Contents>
                    <div className="row-right">
                      <div
                        className="img-corner-right row-center"
                        style={{
                          maxWidth: index === 0 ? "150px" : "180px",
                        }}
                      >
                        <img src={item.img} alt="be" />
                      </div>
                    </div>
                  </div>
                </BoxBennefit>
              ))}
            </Row2ndBenefits>
          </WrapParent>
        )}
      </KeyBenefits>
    </BenefitsContainer>
  );
}

const BOXS_ROW_2ND = [
  {
    title: "Maximized Benefits",
    des: "Users can stake TON to receive iTON and <br /> participate in liquidity provisioning and<br /> farming to earn benefits from DeFi<br /> platforms.",
    img: "/assets/images/landing/img-be-2.webp?v=1",
    clipPath: "polygon(90% 0, 100% 10%, 100% 100%, 10% 100%, 0 90%, 0 0)",
  },
  {
    title: "Efficient Trading",
    des: "iTON Bot Trading helps users optimize <br /> orders and minimize risks while taking <br />advantage of market opportunities.",
    img: "/assets/images/landing/img-be-3.webp?v=1",
    clipPath: "polygon(100% 0, 100% 90%, 90% 100%, 0 100%, 0 90%, 0 0)",
  },
  {
    title: "Play and Airdrop",
    des: "iTON mini-game on Telegram brings fun and iTON rewards, creating an opportunity to interact with the blockchain community in an interesting way.",
    img: "/assets/images/landing/img-be-4.webp?v=1",
    clipPath: "polygon(90% 0, 100% 10%, 100% 100%, 10% 100%, 0 90%, 0 0)",
  },
  {
    title: " Early Access to Innovation",
    des: "Get in early on new projects with our<br />  Launchpad. Invest in groundbreaking ideas<br />  and stay ahead in the Web3 space.",
    img: "/assets/images/landing/img-be-5.webp?v=1",
    clipPath: "polygon(90% 0, 100% 10%, 100% 100%, 0 100%, 0 10%, 10% 0)",
  },
];
