import { styled } from "styled-components";

export const SelectTokenContainer = styled.div`
  position: relative;
  width: 100%;
  border-radius: 6px;
  background: #131313;
`;

export const SelectTokenWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 450px;
  max-height: 300px;
  padding: 10px;
  overflow-y: scroll;
`;

export const SelectTokenList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3px;
`;

export const SelectTokenItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 2px;
  background: #292929;
  width: 100%;
  p {
    color: var(--Neutral-White, #fff);
    font-family: "Rethink Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 18.75px */
  }
`;

export const SelectTokenName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .img-favorite {
    max-width: 30px;
    border-radius: 50%;
    img {
      border-radius: 50%;
    }
  }
  h1 {
    color: var(--Neutral-50, #f7f7f8);
    font-family: "Rethink Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 17.5px */
  }
`;

export const SelectTokenBalance = styled.div``;
