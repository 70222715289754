import { styled } from "styled-components";

export const NumbersProject = styled.div`
  position: relative;
  background-image: url("/assets/images/landing/bg-numbers.webp?v=1");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 100px;
  padding-top: 100px;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  .img-banner {
    position: absolute;
    max-width: 220px;
    right: 60px;
    top: -60px;
    z-index: 2;
    pointer-events: none;
    animation: rotateAround 7s linear infinite;
  }
  .img-light-blue {
    position: absolute;
    max-width: 800px;
    right: -210px;
    top: -345px;
    z-index: 1;
    pointer-events: none;
  }
`;

export const RowProject = styled.div`
  padding: 30px 45px;
  .img-line-bottom {
    width: 100%;
    transform: rotate(180deg);
  }
`;

export const WrapContent = styled.div`
  gap: 20px;
  padding-left: 110px;
`;

export const Numbers = styled.div`
  gap: 40px;
  width: 600px;
  .img-star {
    animation: zoomInOut 2s linear infinite;
    max-width: 35px;
  }
  h1 {
    color: #fff;
    -webkit-text-stroke-width: 2;
    -webkit-text-stroke-color: #fff;
    font-family: "Turret Road";
    font-size: 120px;
    font-style: normal;
    font-weight: 400;
    line-height: 153.6px; /* 128% */
    letter-spacing: -1px;
  }
`;

export const DesProject = styled.div`
  h2 {
    color: #fff;
    font-family: "Rethink Sans";
    font-size: 44px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px; /* 127.273% */
  }
  h3 {
    color: rgba(255, 255, 255, 0.6);
    font-family: "Rethink Sans";
    font-size: 44px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px;
  }
`;

export const HighlightContainer = styled.div`
  position: relative;
  margin-bottom: 50px;
  .img-line {
    max-width: 600px;
    position: absolute;
    bottom: -70px;
    right: -240px;
  }
  @media screen and (max-width: 1281px) {
    ${WrapContent} {
      padding-left: 40px;
    }
    ${Numbers} {
      width: 50%;
      min-width: 200px;
    }
    .img-line {
      max-width: 400px;
      bottom: -20px;
      right: -140px;
    }
  }
  @media screen and (max-width: 1025px) {
    .img-banner {
      max-width: 180px;
    }
    .img-light-blue {
      max-width: 700px;
    }
    .img-line {
      max-width: 300px;
      bottom: 0px;
      right: -180px;
    }
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 100px;
    .img-line {
      max-width: 300px;
      bottom: -25px;
      right: 0px;
    }
    ${NumbersProject} {
      width: 100%;
      padding-top: 0px;
      background-image: url("/assets/images/landing/bg-highligh-mb.webp?v=1");
      margin-top: 70px;
      padding-top: 10px;
      background-size: 100% 100%;
      border-left: none;
      .img-logo {
        max-width: 100px;
        position: absolute;
        top: -60px;
        right: 40px;
        animation: rotateAround 7s linear infinite;
        z-index: 2;
      }
    }
    ${WrapContent} {
      padding-left: 0;
      gap: 40px;
      width: 100%;
      &:nth-child(1) {
        padding-top: 20px;
      }
      &:last-child {
        padding-bottom: 50px;
      }
    }
    ${RowProject} {
      width: 100%;
      padding: 0;
    }
    ${Numbers} {
      gap: 10px;
      min-width: auto;
      padding-left: 20px;
      h1 {
        color: #fff;
        -webkit-text-stroke-width: 1;
        -webkit-text-stroke-color: #fff;
        font-family: "Turret Road";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 123.6px; /* 320% */
      }
    }
    ${DesProject} {
      padding-right: 10px;
      h2 {
        color: #fff;
        font-family: "Rethink Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 116.667% */
      }
      h3 {
        color: rgba(255, 255, 255, 0.6);
        font-family: "Rethink Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
    }
  }
`;
