import { styled } from "styled-components";
import {
  ButtonCommonContainer,
  ButtonCommonWrap,
} from "../Common/Button/styled";

export const HeaderContainer = styled.div`
  position: fixed;
  width: 100%;
  padding: 18px 20px;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(12.5px);
  @media screen and (max-width: 767px) {
    padding: 15px;
    padding-right: 0;
  }
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto;
`;

export const HeaderLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .icon-logo {
    max-width: 140px;
  }
  @media screen and (max-width: 767px) {
    .icon-logo {
      max-width: 120px;
    }
  }
`;

export const HeaderMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  ${ButtonCommonContainer} {
    margin-left: 20px;
  }
  ${ButtonCommonWrap} {
    gap: 15px;
    p {
      margin-left: 0;
    }
  }
`;

export const HeaderMenuItem = styled.div`
  .active {
    color: #f1f1f1;
    transition: all.3s;
  }
  span {
    color: #585660;
    text-align: center;
    font-family: "Rethink Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 17.36px; /* 115.733% */
    letter-spacing: 0.28px;
    transition: all.3s;
    &:hover {
      color: #f1f1f1;
      transition: all.3s;
    }
  }
`;
