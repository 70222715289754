import { styled } from "styled-components";
import { Contents } from "../Banner/styled";
import { ShowTeamMember, ShowTeamWrap } from "../Team/styled";
import { BenefitCarouselWrap } from "../Benefits/styled";

export const StepMap = styled.div`
  width: 100%;
  max-width: 1598.5px;
  height: 480.5px;
  flex-shrink: 0;
  background-color: rgba(43, 43, 43, 1);
  stroke-width: 1px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  stroke: rgba(255, 255, 255, 0.5);
  box-shadow: 0px -8px 0px 0px #373737 inset;
  clip-path: polygon(100% 0, 100% 80%, 95% 100%, 0 100%, 0 0, 10% 0);
  border-radius: 8px;
  position: relative;
  transition: all.2s;
  .line-corner {
    width: 2px;
    height: 25%;
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    right: 33px;
    bottom: -13px;
    transform: rotate(36deg);
  }
`;

export const MapContent = styled.div`
  max-width: 1000px;
  height: 100%;
  margin: auto;
  align-items: center;
  position: relative;
  .img-map {
    max-width: 260px;
    pointer-events: none;
  }
  .img-light-map {
    max-width: 600px;
    position: absolute;
    right: -190px;
    mix-blend-mode: plus-lighter;
    pointer-events: none;
  }
`;

export const MapTitle = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  h1 {
    color: #fff;
    font-family: "Turret Road";
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 40.8px; /* 63.75% */
    letter-spacing: -0.4px;
  }
  h2 {
    color: #fff;
    font-family: "Rethink Sans";
    font-size: 44px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px; /* 109.091% */
    text-transform: capitalize;
    margin: 20px 0;
  }
  ${Contents} {
    p {
      color: var(--Neutral-50, #f7f7f8);
      font-family: "Rethink Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 133.333% */
    }
  }
`;

export const WrapSteps = styled.div`
  position: relative;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  padding-top: 80px;
  transition: all.2s;
  .active {
    transition: all.2s;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.2) 100%
      ),
      linear-gradient(180deg, #25376e 0%, #0043ec 113.22%);
    stroke-width: 2px;
    stroke: #bbf7c6;
    box-shadow: -8px 8px 40px 0px rgba(255, 255, 255, 0.3) inset,
      8px -8px 40px 0px rgba(255, 255, 255, 0.3) inset;
    p {
      color: #fff !important;
    }
  }
`;

export const RoadMapContainer = styled.div`
  position: relative;
  background-image: url("/assets/images/landing/bg-map.webp?v=1");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 0px;
  padding-top: 120px;
  @media screen and (max-width: 1281px) {
    .line-corner {
      transform: rotate(32deg);
      bottom: -13px;
      right: 30px;
    }
  }
  @media screen and (max-width: 1025px) {
    ${StepMap} {
      height: 400px;
    }
    ${MapContent} {
      padding: 0 50px;
      .img-map {
        max-width: 200px;
      }
    }
    .line-corner {
      right: 25px;
      transform: rotate(33deg);
      bottom: -12px;
    }
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 0px;
    padding-top: 0;
    .line-corner {
      right: -3px;
      transform: rotate(35deg);
      bottom: -13px;
    }
    ${BenefitCarouselWrap} {
      margin-top: 50px;
    }

    ${WrapSteps} {
      padding-top: 0px;
      width: 100%;
      overflow: hidden;
    }
    ${StepMap} {
      max-width: 100%;
      height: 550px;
      padding: 40px 20px;
      clip-path: polygon(100% 0, 100% 90%, 90% 100%, 0 100%, 0 0, 10% 0);
    }
    ${MapContent} {
      flex-direction: column;
      padding: 0;
      .img-map {
        margin-top: 30px;
      }
      p {
        font-size: 14px !important;
        line-height: 17.281px; /* 123.439% */
      }
      .img-cornner {
        max-width: 8px;
        top: -3px;
      }
      ${Contents} {
        padding-left: 15px;
      }
    }
    ${MapContent} {
      max-width: 100%;
    }
    ${MapTitle} {
      h1 {
        color: #fff;
        font-family: "Turret Road";
        font-size: 46.084px;
        font-style: normal;
        font-weight: 700;
        line-height: 29.379px; /* 63.75% */
        letter-spacing: -0.288px;
      }
      h2 {
        color: #fff;
        font-family: "Rethink Sans";
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 106.667% */
        text-transform: capitalize;
      }
    }
  }
`;
