import { useContext } from "react";
import { WrapParent } from "../../../Layout/Dashboard/styled";
import TitleSection from "../../Common/TitleSection";
import {
  DesProject,
  HighlightContainer,
  Numbers,
  NumbersProject,
  RowProject,
  WrapContent,
} from "./styled";
import { ContextProviderWrapper } from "../../Context";

export default function Highlight() {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  return (
    <HighlightContainer>
      <WrapParent data-aos="fade-right">
        <TitleSection
          pageName="Highlight"
          textLight="Numbers that"
          textBold="Speak Volumes"
        />
      </WrapParent>
      <WrapParent>
        <NumbersProject data-aos="fade-up">
          {!isMobile && (
            <div className="img-banner row-center">
              <img
                src="/assets/images/landing/img-coin-title-page-v2.webp?v=1"
                alt="title"
              />
            </div>
          )}
          <div className="img-light-blue">
            <img src="/assets/images/landing/img-light-blue.webp?v=1" alt="" />
          </div>
          {isMobile && (
            <div className="img-logo row-center">
              <img
                src="/assets/images/landing/img-coin-title-page-v2.webp?v=1"
                alt="title"
              />
            </div>
          )}
          {ROWS.map((item: any, index: number) => (
            <RowProject key={index}>
              <WrapContent className="row-left">
                <Numbers data-aos="fade-up" className="row-left">
                  <div className="img-star row-center">
                    <img
                      src="/assets/images/landing/img-star.webp?v=1"
                      alt="star"
                    />
                  </div>
                  <h1>{item.number}+</h1>
                </Numbers>
                <DesProject data-aos="fade-up">
                  <h2>{item.title}</h2>
                  <h3>{item.des}</h3>
                </DesProject>
              </WrapContent>
              {index < 3 && (
                <div className="img-line-bottom row-center">
                  <img
                    src="/assets/images/landing/img-line.webp?v=1"
                    alt="line"
                  />
                </div>
              )}
            </RowProject>
          ))}
        </NumbersProject>
        <div data-aos="fade-left" className="img-line row-center">
          {isMobile ? (
            <img
              src="/assets/images/landing/img-line-hightlight-mb.webp?v=1"
              alt="line"
            />
          ) : (
            <img
              src="/assets/images/landing/img-line-blue.webp?v=1"
              alt="line"
            />
          )}
        </div>
      </WrapParent>
    </HighlightContainer>
  );
}

const ROWS = [
  {
    number: "50",
    title: "Awards &",
    des: "Recognizition",
  },
  {
    number: "900",
    title: "Projects",
    des: "Completed",
  },
  {
    number: "20",
    title: "Creative",
    des: "Minds",
  },
  {
    number: "20",
    title: "Years Of",
    des: "Experience",
  },
];
