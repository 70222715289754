import TitleSection from "../../Common/TitleSection";
import {
  CarouselFooter,
  Contents,
  FutureCarousel,
  FutureCarouselWrap,
  FutureContainer,
  ItemCarousel,
  LineStep,
  LineSteps,
  LineTop,
} from "./styled";
import { Swiper, SwiperSlide } from "swiper/react";
import { useContext, useEffect, useState } from "react";
import { SwiperButtonNext } from "../../Common/Button/BtnSwiper";
import { ContextProviderWrapper } from "../../Context";
import { WrapParent } from "../../../Layout/Dashboard/styled";

export default function Futures() {
  const [slideActive, setSlideActive] = useState(0);

  const { isMobile } = useContext(ContextProviderWrapper)!;

  const handleNextSwiper = (swiper: any) => {
    const slideTo = slideActive === LIST_PAGE.length - 2 ? 0 : slideActive + 1;
    swiper.slideTo(slideTo);
  };

  const handlePreSwiper = (swiper: any) => {
    const slideTo = slideActive === 0 ? LIST_PAGE.length - 2 : slideActive - 1;
    swiper.slideTo(slideTo);
  };

  const onSetSwiper = (swiper: any) => {
    setSlideActive(swiper.activeIndex);
  };

  useEffect(() => {
    const elmParents = document.getElementById("elm-wrap");
    const elmCarousel = document.getElementById("elm-wrap-carousel");

    if (!isMobile && elmParents && elmCarousel) {
      elmCarousel.style.marginLeft = `${
        elmParents.offsetLeft > 20 ? elmParents.offsetLeft : 20
      }px`;
    }
  }, []);

  return (
    <FutureContainer id="Features">
      <WrapParent id="elm-wrap">
        <LineTop>
          <div className="img-line">
            {isMobile ? (
              <img
                src="/assets/images/landing/img-line-mb.webp?v=1"
                alt="line"
              />
            ) : (
              <img src="/assets/images/landing/img-line.webp?v=1" alt="line" />
            )}
          </div>
        </LineTop>
      </WrapParent>
      <WrapParent data-aos="fade-right">
        <TitleSection
          pageName="Our Services"
          textLight="Key"
          textBold="Futures"
        />
      </WrapParent>
      <FutureCarouselWrap data-aos="fade-left" id="elm-wrap-carousel">
        <FutureCarousel>
          <Swiper
            slidesPerView={isMobile ? 1 : 2}
            spaceBetween={20}
            loop={true}
            autoplay={{
              delay: 3000,
            }}
            pagination={{
              clickable: false,
            }}
            onSlideChange={(swiper: any) => onSetSwiper(swiper)}
          >
            {LIST_PAGE.map((item: any, index: number) => {
              return (
                <SwiperSlide key={index}>
                  <ItemCarousel
                    className={index === slideActive ? "active" : ""}
                  >
                    <div className="icon-setting row-center">
                      <img
                        src="/assets/images/landing/icon-setting.webp?v=1"
                        alt="setting"
                      />
                    </div>
                    <h1 dangerouslySetInnerHTML={{ __html: item.title }}></h1>
                    <Contents className="row-left">
                      <div className="img-corner-white row-center">
                        <img
                          src="/assets/images/icon-corner-white.webp?v=1"
                          alt="icon"
                        />
                      </div>
                      <p dangerouslySetInnerHTML={{ __html: item.des }}></p>
                    </Contents>
                    <div className="img-car row-center">
                      <img src={item.img} alt="" />
                    </div>
                    <div className="img-light row-center">
                      <img
                        src="/assets/images/landing/img-light.webp?v=1"
                        alt=""
                      />
                    </div>
                  </ItemCarousel>
                </SwiperSlide>
              );
            })}
            <CarouselFooter className="row-left">
              {!isMobile && (
                <SwiperButtonNext
                  onNext={handleNextSwiper}
                  onPre={handlePreSwiper}
                />
              )}
              <LineSteps className="row-left">
                {LIST_PAGE.map((item: any, index: number) => {
                  return (
                    index < 6 && (
                      <LineStep
                        key={index}
                        className={index === slideActive ? "active" : ""}
                      ></LineStep>
                    )
                  );
                })}
              </LineSteps>
            </CarouselFooter>
            {isMobile && (
              <div className="row-right">
                <SwiperButtonNext
                  onNext={handleNextSwiper}
                  onPre={handlePreSwiper}
                />
              </div>
            )}
          </Swiper>
        </FutureCarousel>
      </FutureCarouselWrap>
      <WrapParent>
        <LineTop>
          <div className="img-line-bottom">
            {isMobile ? (
              <img
                src="/assets/images/landing/img-line-mb.webp?v=1"
                alt="line"
              />
            ) : (
              <img src="/assets/images/landing/img-line.webp?v=1" alt="line" />
            )}
          </div>
        </LineTop>
      </WrapParent>
    </FutureContainer>
  );
}

const LIST_PAGE = [
  {
    title: "DEX Aggregator",
    des: "Integrates Multiple Decentralized Exchanges on TON Blockchain.",
    img: "/assets/images/landing/img-car-1.webp?v=1",
  },
  {
    title: "Liquidity Staking",
    des: "Stake TON to receive iTON or TON Token rewards.",
    img: "/assets/images/landing/img-car-3.webp?v=1",
  },
  {
    title: "Provide Liquidity & <br /> Farming",
    des: "Provide Liquidity into DEX trading pairs to maintain<br /> liquidity, receive rewards from trading fees and<br /> farming platform incentives.",
    img: "/assets/images/landing/img-car-4.webp?v=1",
  },
  {
    title: "Trading Bot",
    des: "Automatic trading bots help users optimize buy and<br /> sell orders, reduce slippage and transaction fees.",
    img: "/assets/images/landing/img-car-4.webp?v=1",
  },
  {
    title: "Telegram Mini Games",
    des: "iTON Mini Game is highly interactive, accessible, and<br /> encourages players to learn more about DeFi on TON<br /> Blockchain.",
    img: "/assets/images/landing/img-car-5.webp?v=1",
  },
  {
    title: "Launchpad",
    des: "iTON Launchpad helps new projects raise capital<br /> through Token sales.",
    img: "/assets/images/landing/img-car-6.webp?v=1",
  },
  {
    title: "DEX Aggregator",
    des: "Integrates Multiple Decentralized Exchanges on TON Blockchain.",
    img: "/assets/images/landing/img-car-1.webp?v=1",
  },
];
