import { Dispatch, createSlice } from "@reduxjs/toolkit";

interface DriverState {
  allAssets: any;
  tokenFrom:any;
  tokenTo:any;
  balanceList:any;
  resultSimulate:any;
}

const initialState: DriverState = {
  allAssets: {},
  tokenFrom: null,
  tokenTo : null,
  balanceList: null,
  resultSimulate:null,
};

const assetsSlice = createSlice({
  name: "assetsSlice",
  initialState,
  reducers: {
    getAllAssets(state, action) {
      state.allAssets = action.payload;
    },
    setTokenFrom(state, action) {
      state.tokenFrom = action.payload;
    },
    setTokenTo(state, action) {
      state.tokenTo = action.payload;
    },
    setBanlance(state, action) {
      state.balanceList = action.payload;
    },
    setResultSimulate(state, action) {
      state.resultSimulate = action.payload;
    },
  },
});

export function getDataAllAssets(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(assetsSlice.actions.getAllAssets(data));
    } catch (error) {
      console.log(error);
    }
  };
}
export const { setTokenFrom , setTokenTo , setBanlance , setResultSimulate } = assetsSlice.actions;
const assetsReducer = assetsSlice.reducer;

export default assetsReducer;
