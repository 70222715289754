import { Outlet, useLocation } from "react-router-dom";
import {
  DashboardContainer,
  DashboardParent,
  LoadingContainer,
  WrapMain,
} from "./styled";
import Header from "../../components/Header";
import { useContext, useEffect, useState } from "react";
import { ContextProviderWrapper } from "../../components/Context";
import FooterMobile from "../../components/Footer";
import Footer from "../../components/Footer";
import { ROUTER_PATHS } from "../../routers";

const LayoutDashboard = () => {
  const { pathname } = useLocation();
  const { isMobile } = useContext(ContextProviderWrapper)!;

  const [isFirstVisit, setIsFirstVisit] = useState(false);

  useEffect(() => {
    const firstVisit = localStorage.getItem("firstVisit");
    if (firstVisit === "visited") return;
    setIsFirstVisit(true);
    setTimeout(() => {
      window.scrollTo(0, 0);
      localStorage.setItem("firstVisit", "visited");
      setIsFirstVisit(false);
    }, 3000);
  }, []);

  return (
    <DashboardParent>
      <LoadingContainer
        style={{
          opacity: isFirstVisit ? 1 : 0,
          position: isFirstVisit ? "relative" : "absolute",
          zIndex: isFirstVisit ? 1 : -999,
        }}
      >
        <div className="loader">
          <div className="load-text">
            <div className="loaded-text row-center">
              <img src="/assets/images/landing/logo-lg.webp" alt="" />
            </div>
            <div className="loading-text row-center">
              <span>ITON</span>
            </div>
          </div>
        </div>
      </LoadingContainer>
      <WrapMain
        style={{
          opacity: !isFirstVisit ? 1 : 0,
          position: !isFirstVisit ? "relative" : "absolute",
        }}
      >
        <Header />
        <div className="img-light-corner">
          <img src="/assets/images/landing/img-light.webp?v=1" alt="light" />
        </div>
        <DashboardContainer>
          <Outlet />
        </DashboardContainer>
        {pathname !== ROUTER_PATHS.BUY_ICO && <Footer />}
      </WrapMain>
    </DashboardParent>
  );
};
export default LayoutDashboard;
