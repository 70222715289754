import ButtonCommon from "../../Common/Button";
import { DesContainer, DesWrap, DesWrapButton } from "./styled";

export default function Description() {
  return (
    <DesContainer>
      <div className="img-gradient">
        <img src="/assets/images/landing/img-gradient.webp?v=1" alt="" />
      </div>
      <DesWrap>
        <div className="img-star">
          <img src="/assets/images/landing/img-star.webp?v=1" alt="" />
        </div>
        <p data-aos="fade-right">
          At iTON, <span>we aim to empower our users by</span> providing an
          all-in-one ecosystem <span>that</span> simplifies your Web3
          experience.
        </p>
        <p data-aos="fade-left">
          <span>From effortless trading to</span> earning passive income, and
          discovering new projects to automated tools,{" "}
          <span>we ensure that every aspect of your journey</span> is fast,
          secure, and rewarding
        </p>
        <DesWrapButton>
          <ButtonCommon text="ABOUT US" bgColor="#43424A" />
        </DesWrapButton>
      </DesWrap>
      <div className="img-right">
        <img src="/assets/images/landing/img-des-right.webp?v=1" alt="right" />
      </div>
    </DesContainer>
  );
}
