import { styled } from "styled-components";

export const DesWrap = styled.div`
  position: relative;
  max-width: 740px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 200px;
  .img-star {
    max-width: 50px;
    position: absolute;
    left: 0;
    top: -80px;
    animation: zoomInOut 2s linear infinite;
  }
  p {
    color: #fff;
    font-family: "Rethink Sans";
    font-size: 44px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px; /* 127.273% */
    letter-spacing: -0.5px;
    &:nth-child(2) {
      margin-bottom: 40px;
    }
  }
  span {
    color: rgba(255, 255, 255, 0.6);
    font-family: "Rethink Sans";
    font-size: 44px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px;
    letter-spacing: -0.5px;
  }
`;

export const DesWrapButton = styled.div`
  margin-top: 100px;
  p {
    color: #fff;
    font-family: "Rethink Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 24px */
    text-transform: uppercase;
    margin-bottom: 0 !important;
  }
`;

export const DesContainer = styled.div`
  position: relative;
  .img-right {
    position: absolute;
    bottom: -40px;
    right: 0;
    width: calc(100% / 9);
    max-width: 220px;
    animation: upAndDown 3s linear infinite;
  }
  .img-gradient {
    max-width: 1403px;
    position: absolute;
    top: -450px;
    border-radius: 1403px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #adbfff 0%,
      rgba(37, 55, 110, 0) 100%
    );
    mix-blend-mode: lighten;
    mix-blend-mode: lighten;
    filter: blur(250px);
  }

  @media screen and (max-width: 1025px) {
    ${DesWrap} {
      margin-top: 150px;
      p,
      span {
        font-size: 32px;
        line-height: 36px;
      }
    }
    ${DesWrapButton} {
      margin-top: 70px;
      p {
        font-size: 24px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 0 20px;
    .img-right {
      width: 100px;
    }
    .img-gradient {
      width: 450px;
      top: -110px;
      filter: blur(99px);
      mix-blend-mode: plus-lighter;
    }
    ${DesWrap} {
      margin-top: 120px;
    }
    ${DesWrap} {
      .img-star {
        max-width: 30px;
        top: -50px;
      }
      p,
      span {
        font-size: 24px;
        line-height: 32px;
      }
      p {
        &:nth-child(2) {
          margin-bottom: 30px;
        }
      }
    }
    ${DesWrapButton} {
      margin-top: 70px;
      p {
        font-size: 20px;
      }
    }
  }
`;
