import { AppContainer } from "./styled";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { useLocation } from "react-router-dom";
import { ContextProviderWrapper } from "./components/Context";
import { Toaster } from "react-hot-toast";
import { useContext, useEffect } from "react";
import { store } from "./store/store";
import { Provider } from "react-redux";
import { ReactLenis } from "@studio-freight/react-lenis";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Router, { ROUTER_PATHS } from "./routers";
import "./App.css";
import "swiper/css";
import "animate.css";

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => {
      AOS.init();
      AOS.refresh();
    }, 1000);
  }, []);

  return (
    <Provider store={store}>
      <TonConnectUIProvider manifestUrl="https://iton.co/manifest.json">
        <Toaster
          position="top-right"
          reverseOrder={false}
          toastOptions={{
            style: {
              fontFamily: "Syne",
            },
          }}
        />
        {pathname === ROUTER_PATHS.BUY_ICO ? (
          <AppContainer>
            <Router />
          </AppContainer>
        ) : (
          <ReactLenis
            root
            options={{
              duration: 3,
            }}
          >
            <AppContainer>
              <Router />
            </AppContainer>
          </ReactLenis>
        )}
      </TonConnectUIProvider>
    </Provider>
  );
};

export default App;
