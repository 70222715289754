import { styled } from "styled-components";

export const LanddingPageMain = styled.div`
  position: relative;
  margin: 0 auto;
`;

export const LanddingPageContainer = styled.div`
  position: relative;
  margin: 0 auto;
`;
