import styled from "styled-components";

export const DashboardParent = styled.div`
  position: relative;
  .img-light-corner {
    max-width: 400px;
    position: absolute;
    top: 0px;
    right: -90px;
    transform: rotate(-90deg);
  }
  @media screen and (max-width: 767px) {
    overflow: hidden;
  }
  @keyframes zoomInOut {
    0%,
    100% {
      transform: scale(0.7);
    }
    50% {
      transform: scale(1.1);
    }
  }

  @keyframes upAndDown {
    0% {
      transform: translateY(-30px);
    }
    25% {
      transform: translateY(-15px);
    }
    50% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(-15px);
    }
    100% {
      transform: translateY(-30px);
    }
  }
  @keyframes rotateAround {
    0% {
      transform: rotateZ(0deg);
    }
    25% {
      transform: rotateZ(180deg);
    }
    50% {
      transform: rotateZ(360deg);
    }
    75% {
      transform: rotateZ(540deg);
    }
    100% {
      transform: rotateZ(720deg);
    }
  }
  @keyframes upDownAndRotate {
    0% {
      transform: translateY(40px);
    }
    25% {
      transform: translateY(-20px);
    }
    50% {
      transform: translateY(0px);
    }
    75% {
      transform: translateY(20px);
    }
    100% {
      transform: translateY(40px);
    }
  }
  @keyframes upDownAndRotateMB {
    0% {
      transform: translateY(20px);
    }
    25% {
      transform: translateY(-10px);
    }
    50% {
      transform: translateY(0px);
    }
    75% {
      transform: translateY(15px);
    }
    100% {
      transform: translateY(20px);
    }
  }
`;

export const DashboardContainer = styled.div`
  position: relative;
  min-height: calc(100vh);
  margin: 0 auto;
  padding-right: 0;
  padding-top: 90px;
  // z-index: 201;
  @media screen and (max-width: 768px) {
    padding-top: 90px;
  }
`;

export const WrapParent = styled.div`
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
`;

export const WrapMain = styled.div`
  position: relative;
  background-color: transparent;
`;

export const LoadingContainer = styled.div`
  .loader {
    position: fixed;
    left: 0;
    top: 0;
    background: linear-gradient(
      180deg,
      #0d0c0c 4.2%,
      #1b1f24 14.43%,
      #0b0c0e 28.98%,
      #1b1f24 39.99%,
      #0f1113 64.97%,
      #101214 100%
    );
    width: 100%;
    height: 100vh;
  }

  .load-text {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    font-size: 5vmax;
    width: fit-content;
    display: flex;
  }

  .loaded-text {
    transform: scale(1.5);
    animation: scaleText 0.5s linear 0.5s forwards;
    max-width: 70px;
  }

  .loading-text {
    width: 0;
    overflow: hidden;
    animation: expand 1.5s linear 1.5s forwards;
    font-family: "Bakbak One", sans-serif;
    font-weight: 400;
    color: #fff;
    font-style: normal;
  }

  @keyframes scaleText {
    from {
      transform: scale(1.4);
    }
    to {
      transform: scale(1);
    }
  }

  @keyframes expand {
    from {
      width: 0;
    }
    to {
      width: 12vmax;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      z-index: -1;
    }
  }
`;
