import { useEffect } from "react";
import Banner from "../../components/Landing/Banner";
import Benefits from "../../components/Landing/Benefits";
import Description from "../../components/Landing/Description";
import Futures from "../../components/Landing/Futures";
import Highlight from "../../components/Landing/Highlight";
import Partners from "../../components/Landing/Partners";
import RoadMap from "../../components/Landing/RoadMap";
import Team from "../../components/Landing/Team";
import { LanddingPageContainer, LanddingPageMain } from "./styled";

export default function LanddingPage() {
  return (
    <LanddingPageMain>
      <LanddingPageContainer>
        <Banner />
        <Description />
        <Futures />
        <Benefits />
        <Highlight />
        {/* <Team /> */}
        <RoadMap />
        <Partners />
      </LanddingPageContainer>
    </LanddingPageMain>
  );
}
