import { PageName } from "../Title/styled";
import { TextBold, TextLight, TitleSectionWrap } from "./styled";

type TitleSectionType = {
  pageName: string;
  textLight: string;
  textBold: string;
};

export default function TitleSection({
  pageName,
  textLight,
  textBold,
}: TitleSectionType) {
  return (
    <TitleSectionWrap>
      <PageName>
        <div className="img-title-page">
          <img src="/assets/images/icon-title-page.webp?v=1" alt="title" />
        </div>
        <h1>{pageName}</h1>
      </PageName>
      <TextLight>{textLight}</TextLight>
      <TextBold>{textBold}</TextBold>
    </TitleSectionWrap>
  );
}
