import { styled } from "styled-components";
import { TableNextPage } from "../../../page/Liquidity/styled";

export const LineTop = styled.div`
  height: 50px;
  width: 100%;
  .img-line {
    max-width: 1800px;
    margin-bottom: 70px;
  }
  .img-line-bottom {
    max-width: 1800px;
    margin-top: 70px;
    img {
      transform: rotate(180deg);
    }
  }
`;

export const FutureCarouselWrap = styled.div`
  width: 100vw;
  overflow: hidden;
  position: relative;
  margin-top: 70px;
`;

export const ItemCarousel = styled.div`
  position: relative;
  width: 1000px;
  height: 547px;
  background-color: rgba(61, 61, 61, 0.5);
  stroke: #bbf7c6;
  box-shadow: -8px 8px 40px 0px rgba(255, 255, 255, 0.3) inset,
    8px -8px 40px 0px rgba(255, 255, 255, 0.3) inset;
  clip-path: polygon(100% 0, 100% 85%, 96% 100%, 0 100%, 0 0);
  padding-left: 50px;
  padding-top: 120px;
  border-radius: 10px;
  .icon-setting {
    max-width: 72px;
    mix-blend-mode: screen;
  }
  h1 {
    color: #fff;
    font-family: "Rethink Sans";
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 100% */
    text-transform: capitalize;
    margin-top: 30px;
    margin-bottom: 25px;
    max-width: 400px;
  }
  .img-car {
    max-width: 600px;
    width: calc(100% - 400px);
    position: absolute;
    top: 80px;
    right: -20px;
    z-index: 2;
  }
  .img-light {
    max-width: 580px;
    width: calc(100% - 100px);
    position: absolute;
    bottom: 0px;
    right: 140px;
    z-index: 1;
    mix-blend-mode: plus-lighter;
  }
`;

export const Contents = styled.div`
  position: relative;
  max-width: 470px;
  align-items: flex-start;
  gap: 5px;
  .img-corner-white {
    max-width: 12px;
    margin-top: 5px;
  }
  p {
    color: rgba(255, 255, 255, 0.75);
    font-family: "Rethink Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 144.444% */
  }
`;

export const FutureCarousel = styled.div`
  position: relative;
  width: calc(1000px * 2);
  .active {
    background: linear-gradient(180deg, #25376e 0%, #0043ec 100%);
  }
`;

export const CarouselFooter = styled.div`
  position: relative;
  margin-top: 35px;
  max-width: 1440px;
  ${TableNextPage} {
    margin-right: 5px;
  }
`;

export const LineStep = styled.div`
  width: calc((100% - 200px) / 6);
  height: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  transform: skew(-50deg);
`;

export const LineSteps = styled.div`
  gap: 7px;
  margin-left: 40px;
  padding-right: 40px;
  width: calc(100% - 200px);
  .active {
    background: #0075ff;
    box-shadow: -8px 8px 40px 0px rgba(255, 255, 255, 0.2) inset,
      8px -8px 40px 0px rgba(255, 255, 255, 0.2) inset;
  }
`;

export const FutureContainer = styled.div`
  position: relative;
  margin-top: 100px;
  @media screen and (min-width: 1441px) {
    ${FutureCarousel} {
      width: calc(1400px * 2);
    }

    ${ItemCarousel} {
      width: 1380px;
    }
  }
  @media screen and (max-width: 1025px) {
    ${FutureCarouselWrap} {
      margin-top: 40px;
    }
    ${FutureCarousel} {
      width: calc(810px * 2);
    }
    ${LineStep} {
      width: calc((100% - 500px) / 6);
    }

    ${ItemCarousel} {
      width: 800px;
      height: 400px;
      padding-left: 40px;
      padding-top: 60px;
      .img-car {
        width: 450px;
        bottom: 20px;
      }
      ${Contents} {
        max-width: 380px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    margin-top: 80px;
    ${LineTop} {
      padding: 0 10px;
      .img-line {
        margin-bottom: 20px;
      }
      .img-line-bottom {
        margin-top: 40px;
      }
    }
    ${FutureCarouselWrap} {
      padding: 0 20px;
      margin-top: 20px;
    }
    ${FutureCarousel} {
      width: 100%;
    }
    ${ItemCarousel} {
      padding: 20px;
      width: 100%;
      height: 580px;
      clip-path: polygon(100% 0, 100% 90%, 92% 100%, 0 100%, 0 0);
      h1 {
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 33.686px;
        margin-top: 15px;
        margin-bottom: 10px;
      }
      .img-car {
        bottom: -150px;
        left: -30px;
      }
    }
    ${LineStep} {
      width: calc((100vw - 60px) / 6);
    }
    ${LineSteps} {
      margin-left: 0;
      margin-bottom: 20px;
      width: 100%;
      padding: 0;
    }
  }
`;
