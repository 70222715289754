import { styled } from "styled-components";
import { Contents } from "../Landing/Banner/styled";
import { ButtonCommonContainer } from "../Common/Button/styled";

export const FooterWrap = styled.div`
  position: relative;
  padding: 1px;
  align-items: unset;
  clip-path: polygon(
    3% 0,
    97% 0,
    100% 10%,
    100% 90%,
    97% 100%,
    3% 100%,
    0 90%,
    0 10%
  );
  background-color: #fff;
  .wrap-content {
    padding: 40px;
    width: 100%;
    height: 100%;
    padding-top: 70px;
    background: linear-gradient(
      180deg,
      #020202 0%,
      #1b1f24 22.5%,
      #020202 43%,
      #111316 59.16%,
      #101214 100%
    );
    clip-path: polygon(
      3% 0,
      97% 0,
      100% 10%,
      100% 90%,
      97% 100%,
      3% 100%,
      0 90%,
      0 10%
    );
  }
  ${Contents} {
    p {
      color: var(--Neutral-50, #f7f7f8);
      text-align: center;
      font-family: "Rethink Sans";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 120% */
    }
  }
`;

export const LeftBlock = styled.div`
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  .img-logo-ton {
    max-width: 70px;
  }
`;

export const CenterBlock = styled.div`
  position: relative;
  align-items: flex-start;
  width: 100%;
`;

export const ListLinks = styled.div`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.6);
    font-family: "Rethink Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px; /* 188.889% */
  }
`;

export const RightBlock = styled.div`
  position: relative;
  .input-contact {
    width: 307px;
    flex-shrink: 0;
    background-color: rgba(42, 47, 60, 0.5);
    margin: 10px 0;
    clip-path: polygon(100% 0, 100% 90%, 100% 100%, 0 100%, 0 40%, 10% 0);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    position: relative;
    .line-input {
      background-color: rgba(255, 255, 255, 0.15);
      height: 63%;
      width: 1px;
      position: absolute;
      transform: rotate(46deg);
      z-index: 2;
      top: -9px;
      left: 16px;
    }
    input {
      background-color: transparent;
      width: 100%;
      height: 100%;
      outline: none;
      color: #fff;
      font-size: 24px;
      font-family: "Rethink Sans";
      padding: 20px 0px;
      padding-left: 20px;
      border: none;
    }
  }
`;

export const BottomBlock = styled.div`
  gap: 50px;
  margin-top: 80px;
  width: 100%;
  p {
    color: var(--Neutral-50, #f7f7f8);
    text-align: center;
    font-family: "Rethink Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
  }
  h1 {
    color: var(--Neutral-50, #f7f7f8);
    text-align: center;
    font-family: "Rethink Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
  }
`;

export const LineCenter = styled.div`
  align-items: center;
  width: 100%;
  gap: 20px;
  .line {
    height: 1px;
    width: 100%;
    background: #fff;
  }
`;

export const ListSocial = styled.div`
  gap: 20px;
  .icon-social {
    width: 25px;
    transform: scale(0.8);
    transition: all.3s;
    &:hover {
      transform: scale(1.1);
    }
  }
`;

export const FooterContainer = styled.div`
  position: relative;
  padding-bottom: 80px;
  margin-top: 150px;
  ${ButtonCommonContainer} {
    clip-path: polygon(10% 0, 90% 0, 100% 50%, 90% 99%, 10% 100%, 0% 50%);
  }
  @media screen and (max-width: 1025px) {
    margin-top: 100px;
    ${CenterBlock} {
      flex-wrap: wrap;
      gap: 28px;
    }
    ${BottomBlock} {
      margin-top: 50px;
      p,
      h1 {
        display: none;
      }
    }
    ${Contents} {
      p {
        font-size: 18px;
      }
    }
    ${FooterWrap} {
      clip-path: polygon(
        5% 0,
        95% 0,
        100% 6%,
        100% 95%,
        96% 100%,
        5% 100%,
        0 95%,
        0 5%
      );

      .wrap-content {
        padding: 30px 20px;
        clip-path: polygon(
          5% 0,
          95% 0,
          100% 6%,
          100% 95%,
          96% 100%,
          5% 100%,
          0 95%,
          0 5%
        );
      }
    }
    .input-contact {
      width: 100%;
      margin-bottom: 35px;
    }
    ${LeftBlock} {
      width: 100%;
      .img-logo-ton {
        max-width: 130px;
      }
    }
    ${RightBlock} {
      width: 100%;
      .input-contact {
        clip-path: polygon(100% 0, 100% 90%, 100% 100%, 0 100%, 0 40%, 4% 0);
      }
    }
    ${ButtonCommonContainer} {
      clip-path: polygon(10% 0, 90% 0, 100% 50%, 90% 99%, 10% 100%, 0% 50%);
    }
  }
  @media screen and (max-width: 767px) {
    ${RightBlock} {
      .input-contact {
        clip-path: polygon(100% 0, 100% 90%, 100% 100%, 0 100%, 0 40%, 9% 0);
      }
    }
  }
`;
