import {
  PageNameICO,
  PageTitleICO,
  TableInvestCell,
  TableInvestHead,
  TableInvestRow,
  TokenInvestTable,
  TokenInvestWrap,
  WrapInvestTable,
  WrapPageName,
} from "./styled";

export default function TokenInvest() {
  return (
    <TokenInvestWrap>
      <WrapPageName className="animate__animated animate__fadeInLeft">
        <PageNameICO>
          <div className="img-title-page">
            <img src={"/assets/images/icon-title-page.webp"} alt="title" />
          </div>
          <h1>iTON Tokenomics</h1>
        </PageNameICO>
        <PageTitleICO>
          <p className="text-light">Token </p>
          <p className="text-bold">VESTING</p>
        </PageTitleICO>
      </WrapPageName>
      <WrapInvestTable>
        <TokenInvestTable>
          {TABLE_HEAD.map((item: any, index: number) => (
            <TableInvestHead key={index}>
              <TableInvestCell
                style={{
                  width: "130px",
                }}
              >
                <h1>{item.title}</h1>
              </TableInvestCell>
              <TableInvestCell
                style={{
                  width: "110px",
                }}
              >
                <p>{item.per}</p>
              </TableInvestCell>

              <TableInvestCell
                style={{
                  width: "140px",
                }}
              >
                <p dangerouslySetInnerHTML={{ __html: item.amount }}></p>
              </TableInvestCell>
              <TableInvestCell
                style={{
                  width: "130px",
                }}
              >
                <p>{item.price}</p>
              </TableInvestCell>
              <TableInvestCell
                style={{
                  width: "130px",
                }}
              >
                <p>{item.raise}</p>
              </TableInvestCell>
              <TableInvestCell
                style={{
                  width: "130px",
                }}
              >
                <p dangerouslySetInnerHTML={{ __html: item.fully }}></p>
              </TableInvestCell>
              <TableInvestCell
                style={{
                  width: "100px",
                }}
              >
                <p dangerouslySetInnerHTML={{ __html: item.tgeUnlock }}></p>
              </TableInvestCell>
              <TableInvestCell
                style={{
                  width: "140px",
                }}
              >
                <p dangerouslySetInnerHTML={{ __html: item.tgeAmount }}></p>
              </TableInvestCell>
              <TableInvestCell>
                <p dangerouslySetInnerHTML={{ __html: item.ves }}></p>
              </TableInvestCell>
            </TableInvestHead>
          ))}
          {TABLE_DATAS.map((item: any, index: number) => (
            <TableInvestRow
              key={index}
              style={{
                background: index % 2 === 0 ? "#222" : "#3B3B3B",
              }}
            >
              <TableInvestCell
                style={{
                  width: "100px",
                  marginRight: "30px",
                }}
              >
                <h1>{item.title}</h1>
              </TableInvestCell>
              <TableInvestCell
                style={{
                  width: "110px",
                }}
              >
                <p>{item.per}</p>
              </TableInvestCell>
              <TableInvestCell
                style={{
                  width: "140px",
                }}
              >
                <p>{item.amount}</p>
              </TableInvestCell>
              <TableInvestCell
                style={{
                  width: "130px",
                }}
              >
                <p>{item.price}</p>
              </TableInvestCell>
              <TableInvestCell
                style={{
                  width: "130px",
                }}
              >
                <p>{item.raise}</p>
              </TableInvestCell>
              <TableInvestCell
                style={{
                  width: "130px",
                }}
              >
                <p>{item.fully}</p>
              </TableInvestCell>
              <TableInvestCell
                style={{
                  width: "100px",
                }}
              >
                <p>{item.tgeUnlock}</p>
              </TableInvestCell>
              <TableInvestCell
                style={{
                  width: "140px",
                }}
              >
                <p>{item.tgeAmount}</p>
              </TableInvestCell>
              <TableInvestCell
                style={{
                  width: "300px",
                }}
              >
                <p dangerouslySetInnerHTML={{ __html: item.ves }}></p>
              </TableInvestCell>
            </TableInvestRow>
          ))}
        </TokenInvestTable>
      </WrapInvestTable>
    </TokenInvestWrap>
  );
}

const TABLE_HEAD = [
  {
    title: "",
    per: "Percentage",
    amount: "Amount <br /> Token",
    price: "Price $",
    raise: "Raise $",
    fully: "Fully <br />Diluted Cap $",
    tgeUnlock: "TGE <br /> Unlock",
    tgeAmount: "TGE <br />Amount",
    ves: "Vesting",
  },
];

const TABLE_DATAS = [
  {
    title: "Angel Investor",
    per: "3.5%",
    amount: "17,500,000",
    price: "0,0175",
    raise: "306,250",
    fully: "8,750,000",
    tgeUnlock: "15%",
    tgeAmount: "2,625,000",
    ves: "15% TGE unlocked. Then lock for 1 months. Then unlock 10% monthly",
  },
  {
    title: "Seed Round",
    per: "8.0%",
    amount: "40,000,000",
    price: "0,03",
    raise: "1,200,000",
    fully: "1,200,000",
    tgeUnlock: "15%",
    tgeAmount: "6,000,000",
    ves: "15% TGE then daily linear for 14 months",
  },
  {
    title: "Public Sale",
    per: "5.0%",
    amount: "25,000,000",
    price: "0,042",
    raise: "1,050,000",
    fully: "21,000,000",
    tgeUnlock: "15%",
    tgeAmount: "3,375,000",
    ves: "15% TGE then daily linear for 12 months",
  },
  {
    title: "Liquidity",
    per: "25%",
    amount: "125,000,000",
    price: "-",
    raise: "-",
    fully: "-",
    tgeUnlock: "20%",
    tgeAmount: "25,000,000",
    ves: "20% unlock upon TGE, then cliff for a month and unlock 25% the next month alternatively until 100% in unlocked",
  },
  {
    title: "Advisor & Incubator",
    per: "6.0%",
    amount: "30,000,000",
    price: "-",
    raise: "-",
    fully: "-",
    tgeUnlock: "0%",
    tgeAmount: "0",
    ves: "0 unlock upon TGE, then cliff for 3 months an unlock 10% on 4 months, then unlock 15% quarterly",
  },
  {
    title: "Marketing",
    per: "17%",
    amount: "85,000,000",
    price: "-",
    raise: "-",
    fully: "-",
    tgeUnlock: "3%",
    tgeAmount: "2,550,000",
    ves: "3% TGE unlocked. Then lock for 2 months. Then unlock 15% quarterly",
  },
  {
    title: "Team",
    per: "10.5%",
    amount: "52,500,000",
    price: "-",
    raise: "-",
    fully: "-",
    tgeUnlock: "0%",
    tgeAmount: "0",
    ves: "0 % TGE unlocked. Then lock for 12 months. Then unlock 15% quarterly",
  },
  {
    title: "Game & Airdrop",
    per: "25%",
    amount: "125,000,000",
    price: "-",
    raise: "-",
    fully: "-",
    tgeUnlock: "5%",
    tgeAmount: "6,250,000",
    ves: "5% TGE Unlocked, Unlock 2% per monthly",
  },
  {
    title: "Total",
    per: "100%",
    amount: "500,000,000",
    price: "-",
    raise: "2,556,250",
    fully: "-",
    tgeUnlock: "-",
    tgeAmount: "46,175,000",
    ves: "",
  },
];
