import { styled } from "styled-components";
import { TextLight } from "../../Common/TitleSection/styled";

export const RowHeader = styled.div`
  width: 100%;
  overflow: hidden;
  align-items: flex-start;
  p {
    color: #fff;
    font-family: "Rethink Sans";
    font-size: 34.041px;
    font-style: normal;
    font-weight: 400;
    line-height: 43.324px; /* 127.273% */
    letter-spacing: -0.387px;
    margin-left: 60px;
    margin-right: 40px;
    span {
      color: rgba(255, 255, 255, 0.6);
      font-family: "Rethink Sans";
      font-size: 34.041px;
      font-style: normal;
      font-weight: 400;
      line-height: 43.324px;
      letter-spacing: -0.387px;
    }
  }
  .img-row {
    max-width: 1200px;
  }
  .img-row-sm {
    max-width: 160px;
  }
`;

export const KeyBenefits = styled.div`
  position: relative;
  margin-top: 200px;
  .img-box-gif {
    max-width: 400px;
    pointer-events: none;
    mix-blend-mode: screen;
    img {
      transform: scale(2.5);
    }
  }
  ${TextLight} {
    color: #1b2f7b;
  }
`;

export const WrapBox = styled.div`
  background-image: url("/assets/images/landing/bg-box-light.webp?v=1");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 345px;
  height: 360px;
`;

export const BoxBennefit = styled.div<{ clipPath?: string }>`
  clip-path: ${({ clipPath }) => (clipPath ? clipPath : "")};
  width: 450px;
  padding: 1px;
  padding-right: 2px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  transition: all.5s;
  .box-benefit {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    background: rgba(43, 43, 43, 1);
    padding: 40px;
    padding-right: 30px;
    padding-bottom: 30px;
    box-shadow: 0px -8px 0px 0px #373737 inset;
    clip-path: ${({ clipPath }) => (clipPath ? clipPath : "")};
    margin-right: 2px;
    transition: all.5s;
    h1 {
      font-family: "Rethink Sans";
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px; /* 111.111% */
      text-transform: capitalize;
      background: linear-gradient(92deg, #fff 1.35%, #d3e7ff 55.78%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 20px;
    }
    .img-corner-right {
      margin-top: 30px;
      max-width: 180px;
      transform: scale(0.9);
      transition: all.5s;
    }
    &:hover {
      border-color: #bbf7c6;
      background: linear-gradient(180deg, #25376e 0%, #0043ec 100%);
      box-shadow: 0px 0px 8px 0px rgba(248, 248, 248, 0.6) inset,
        0px 8px 24px -16px rgba(0, 0, 0, 0.4);
      transition: all.5s;
      p {
        color: #fff;
        padding-left: 5px;
        transition: all.5s;
        font-size: 17px;
      }
      .img-corner-right {
        transform: scale(1.1);
        transition: all.5s;
      }
    }
  }
`;
export const Row1stBenefits = styled.div``;

export const Row2ndBenefits = styled.div`
  gap: 20px;
  margin-top: 20px;
  align-items: stretch;
  flex-wrap: wrap;
`;

export const Row3rdBenefits = styled.div`
  gap: 20px;
  margin-top: 20px;
`;

export const BenefitCarouselWrap = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
`;

export const BenefitCarousel = styled.div`
  position: relative;
  width: 100%;
  .active {
    background: linear-gradient(180deg, #25376e 0%, #0043ec 100%);
  }
`;

export const BenefitsContainer = styled.div`
  position: relative;
  padding: 150px 0;
  padding-bottom: 200px;
  background-image: url("/assets/images/landing/bg-light-lg.webp?v=1");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .bg-light-left {
    width: 100vw;
    margin: 0 auto;
    position: absolute;
    top: -20%;
  }
  @media screen and (max-width: 1281px) {
    padding: 80px 0;
    ${BoxBennefit} {
      width: calc((100% - 40px) / 2);
    }
    ${KeyBenefits} {
      margin-top: 100px;
      .img-box-gif {
        max-width: 100%;
        img {
          transform: scale(1.5);
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding: 60px 0;
    ${RowHeader} {
      flex-direction: column;
      gap: 30px;
      .img-row {
        max-width: 100%;
      }
      p,
      span {
        font-size: 24px;
        line-height: 32px; /* 133.333% */
      }
      p {
        margin: 0 20px;
      }
    }
    ${Row1stBenefits} {
      flex-direction: column;
      align-items: flex-start;
    }
    ${WrapBox} {
      margin: 0 auto;
    }
    ${BoxBennefit} {
      width: 100%;
      height: 480px;
    }
  }
`;
