import { styled } from "styled-components";
import { Contents } from "../Banner/styled";

export const WrapPartners = styled.div`
  margin-bottom: 150px;
  position: relative;
  .img-line-partern {
    width: 100%;
    position: absolute;
    bottom: -70px;
  }
`;

export const WrapRows = styled.div`
  margin-top: 100px;
`;

export const RowsPartners = styled.div`
  gap: 20px;
  margin-bottom: 20px;
`;

export const ItemPartners = styled.div`
  .img-partern {
    max-width: 300px;
  }
`;

export const WrapContact = styled.div`
  position: relative;
  height: 500px;
  margin-bottom: 100px;
  flex-direction: column;
  ${Contents} {
    width: 100%;
    .img-cornner {
      left: 35px;
    }
    p {
      color: var(--Neutral-50, #f7f7f8);
      text-align: center;
      font-family: "Rethink Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 133.333% */
    }
  }
  h1 {
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    font-family: "Rethink Sans";
    font-size: 44px;
    font-style: normal;
    font-weight: 500;
    line-height: 56px; /* 127.273% */
    letter-spacing: -0.5px;
    z-index: 2;
    margin-top: 30px;
    margin-bottom: 10px;
    span {
      color: #fff;
      font-family: "Rethink Sans";
      font-size: 44px;
      font-style: normal;
      font-weight: 500;
      line-height: 56px;
      letter-spacing: -0.5px;
    }
  }
  .img-contact {
    width: 100%;
    bottom: 0;
    position: absolute;
  }
`;

export const WrapButton = styled.div`
  margin-top: 70px;
`;

export const PartnersContainer = styled.div`
  position: relative;
  margin-top: 150px;
  @media screen and (max-width: 1281px) {
    ${WrapContact} {
      height: 450px;
      h1 {
        margin-top: 50px;
      }
    }
  }
  @media screen and (max-width: 1025px) {
    margin-top: 30px;
    ${WrapRows} {
      margin-top: 70px;
    }
    ${WrapPartners} {
      margin-bottom: 100px;
    }
    ${WrapContact} {
      h1 {
        margin-top: 150px;
      }
    }
    ${WrapButton} {
      margin-top: 50px;
    }
  }
  @media screen and (max-width: 767px) {
    margin-top: 0;
    .img-line-partern {
      width: 1000px;
    }
    ${RowsPartners} {
      flex-wrap: wrap;
    }

    ${WrapRows} {
      margin-top: 40px;
    }

    ${ItemPartners} {
      width: calc((100% - 20px) / 2);
      .img-partern {
        width: 100%;
      }
    }

    ${WrapContact} {
      height: 400px;
      h1 {
        margin-top: 120px;
        color: rgba(255, 255, 255, 0.6);
        text-align: center;
        font-family: "Rethink Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 12.309px; /* 51.289% */
        letter-spacing: -0.11px;
      }
      span {
        color: #fff;
        font-family: "Rethink Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 12.309px;
        letter-spacing: -0.11px;
      }
      p {
        color: var(--Neutral-50, #f7f7f8);
        font-family: "Rethink Sans";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 153.846% */
      }
    }
  }
`;
