import { useContext } from "react";
import { WrapParent } from "../../../Layout/Dashboard/styled";
import ButtonCommon from "../../Common/Button";
import {
  BannerContainer,
  BannerWrap,
  Contents,
  Title,
  ViewImage,
  WrapButton,
} from "./styled";
import { ContextProviderWrapper } from "../../Context";
import { useNavigate } from "react-router";
import { ROUTER_PATHS } from "../../../routers";
import { Link } from "react-router-dom";

export default function Banner() {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const navigate = useNavigate();
  return (
    <BannerContainer id="home">
      <div className="img-bg-header">
        <img src="/assets/images/landing/bg-header.webp?v=1" alt="header" />
      </div>
      <BannerWrap>
        <WrapParent>
          <div className="img-banner animate__animated animate__fadeInRight row-center">
            <img
              className="animate__animated animate__fadeIn"
              src="/assets/images/landing/img-coin-title-page-v2.webp?v=1"
              alt="title"
            />
          </div>
          <Title className="animate__animated animate__fadeInLeft">
            <h1>
              <span>iTON</span> – Unlock the <br /> Future of DeFi on TON
              <br />
              Blockchain
            </h1>
          </Title>
          <Contents className="animate__animated animate__fadeInRight">
            <div className="img-cornner">
              <img src="/assets/images/icon-corner-white.webp?v=1" alt="" />
            </div>
            <p>
              iTON is an integrated platform that allows users to participate in
              various activities on the TON Blockchain. iTON optimizes user
              experience in Trading, Provide Liquidity & Farming, Staking and
              Participating in DeFi activities.
            </p>
          </Contents>
        </WrapParent>
      </BannerWrap>
      <ViewImage className="animate__animated animate__fadeInUp">
        <div className="img-left">
          <img src="/assets/images/landing/img-banner-left.webp?v=1" alt="" />
        </div>
        <WrapParent>
          <WrapButton className="row-between">
            <Link to={ROUTER_PATHS.BUY_ICO} target="_blank">
              <ButtonCommon text="Buy IDO" bgColor="#43424A" />
            </Link>

            {isMobile && (
              <div className="img-banner-mb row-center">
                <img
                  src="/assets/images/landing/img-coin-title-page-v2.webp?v=1"
                  alt="title"
                />
              </div>
            )}
          </WrapButton>
          <div className="img-main">
            <img
              id="img-banner"
              src="/assets/images/landing/img-banner.webp?v=1"
              alt="banner"
            />
          </div>
          <div className="img-light-blue">
            <img
              src="/assets/images/landing/img-light-blue.webp?v=1"
              alt="light"
            />
          </div>
          <div className="img-mascot">
            <img src="/assets/images/landing/img-mascot.webp?v=1" alt="" />
          </div>
          <div className="img-eart">
            <img src="/assets/images/landing/img-eart.webp?v=1" alt="" />
          </div>
          <div className="img-star-down">
            <img src="/assets/images/landing/img-star-down.webp?v=1" alt="" />
          </div>
          <div className="img-rocket">
            <img src="/assets/images/landing/img-rocket.webp?v=1" alt="" />
          </div>
          <div className="img-star-1">
            <img src="/assets/images/landing/img-star.webp?v=1" alt="" />
          </div>
          <div className="img-star-2">
            <img src="/assets/images/landing/img-star.webp?v=1" alt="" />
          </div>
          <div className="img-star-3">
            <img src="/assets/images/landing/img-star.webp?v=1" alt="" />
          </div>
        </WrapParent>
      </ViewImage>
    </BannerContainer>
  );
}
