import { useContext, useEffect } from "react";
import {
  ModalClose,
  ModalHeader,
  ModalMain,
  ModalOverlayContainer,
  ModalOverlayInner,
  ModalOverlayWrapper,
  ModalTitle,
} from "./styled";
import { ContextProviderWrapper } from "../../Context";
import { TableSearch } from "../../../page/Liquidity/styled";

type ModalOverlayType = {
  component?: any;
  open?: any;
  setOpen?: any;
  title?: any;
  width?: any;
  onClickParent?: any;
  onChange?: any;
  isSearch?: any;
  top?: any;
};

const ModalOverlay = ({
  component,
  open,
  setOpen,
  title,
  width,
  onClickParent,
  onChange,
  isSearch = true,
  top,
}: ModalOverlayType) => {
  const { theme, isMobile } = useContext(ContextProviderWrapper)!;

  useEffect(() => {
    if (open) {
      document.body.classList.add("hidden");
    } else {
      document.body.classList.remove("hidden");
    }
  }, [open]);

  return (
    <ModalOverlayContainer open={open} onClick={onClickParent}>
      <ModalOverlayWrapper
        className={open && isMobile ? "active" : ""}
        width={width}
        style={{
          top: top ? top : "",
        }}
      >
        <ModalOverlayInner className={theme}>
          <ModalClose>
            <div
              className="img-close"
              onClick={() => {
                setOpen(false);
              }}
            >
              <img src="/assets/images/icon-close.webp?v=1" alt="" />
            </div>
          </ModalClose>
          <ModalHeader>
            {title && (
              <ModalTitle className={theme}>
                <div className="img-title">
                  <img
                    src="/assets/images/BuyICO/im-title-ico.png"
                    alt="title"
                  />
                </div>
                <h1>{title}</h1>
              </ModalTitle>
            )}
            {isSearch && (
              <TableSearch>
                <input
                  type="text"
                  placeholder="Enter token name or token address"
                  onChange={onChange}
                />
                <div className="icon-search">
                  <img src="/assets/images/icon-search.webp?v=1" alt="search" />
                </div>
              </TableSearch>
            )}
          </ModalHeader>
          <ModalMain>{component}</ModalMain>
        </ModalOverlayInner>
      </ModalOverlayWrapper>
    </ModalOverlayContainer>
  );
};

export default ModalOverlay;
