export const CONFIG = [
  {
    label: "Swap",
    link: "/swap",
  },
  {
    label: "Liquidity",
    link: "/liquidity",
  },
  {
    label: "Farming",
    link: "/farming",
  },
  {
    label: "Launchpad",
    link: "/launchpad",
  },
  {
    label: "Share Pool",
    link: "/share-pool",
  },
];

export const TIME_SALE = {
  hour: 14,
  angel: {
    start: "10/26/2024",
  },
  seed: {
    start: "11/12/2024",
  },
  public: {
    start: "12/01/2024",
  },
};
