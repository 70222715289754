import { Link } from "react-router-dom";
import { WrapParent } from "../../Layout/Dashboard/styled";
import {
  BottomBlock,
  CenterBlock,
  FooterContainer,
  FooterWrap,
  LeftBlock,
  LineCenter,
  ListLinks,
  ListSocial,
  RightBlock,
} from "./styled";
import { Contents } from "../Landing/Banner/styled";
import ButtonCommon from "../Common/Button";

export default function Footer() {
  return (
    <FooterContainer data-aos="fade-up">
      <WrapParent>
        <FooterWrap>
          <div className="wrap-content">
            <CenterBlock className="row-between">
              <LeftBlock className="row-between">
                <div className="img-logo-ton">
                  <img src="/assets/images/landing/logo.webp?v=1" alt="logo" />
                </div>
              </LeftBlock>
              <ListLinks className="row-left">
                <Contents className="row-left">
                  <div className="img-cornner">
                    <img
                      src="/assets/images/icon-corner-white.webp?v=1"
                      alt=""
                    />
                  </div>
                  <p>OUR SERVICE</p>
                </Contents>
                <Link to="#">Docs</Link>
                <Link to="#">Documentation</Link>
                <Link to="#">Terms of use</Link>
                <Link to="#">Privacy policy</Link>
              </ListLinks>
              <ListLinks className="row-left">
                <Contents className="row-left">
                  <div className="img-cornner">
                    <img
                      src="/assets/images/icon-corner-white.webp?v=1"
                      alt=""
                    />
                  </div>
                  <p>OUR PRODUCT</p>
                </Contents>
                <Link to="#">About us</Link>
                <Link to="#">Home</Link>
                <Link to="#">How it works</Link>
                <Link to="#">Roadmap</Link>
              </ListLinks>
              <ListLinks className="row-left">
                <Contents className="row-left">
                  <div className="img-cornner">
                    <img
                      src="/assets/images/icon-corner-white.webp?v=1"
                      alt=""
                    />
                  </div>
                  <p>TERMS OF USE</p>
                </Contents>
                <Link to="#">Privacy Policy </Link>
                <Link to="#">Informed Consent</Link>
              </ListLinks>
              <RightBlock>
                <Contents className="row-left">
                  <div className="img-cornner">
                    <img
                      src="/assets/images/icon-corner-white.webp?v=1"
                      alt=""
                    />
                  </div>
                  <p>LET’S STAY IN TOUCH</p>
                </Contents>
                <div className="input-contact">
                  <div className="line-input"></div>
                  <input type="text" />
                </div>
                <div className="row-right">
                  <ButtonCommon width="130px" text="SENT" bgColor="#43424A" />
                </div>
              </RightBlock>
            </CenterBlock>
            <BottomBlock className="row-between">
              <p>iTON</p>
              <LineCenter className="row-center">
                <div className="line"></div>
                <ListSocial className="row-center">
                  <a
                    className="icon-social row-center"
                    target="_blank"
                    href="https://t.me/iTONOfficial"
                  >
                    <img src="/assets/images/landing/icon-tele.svg" alt="" />
                  </a>
                  <a
                    className="icon-social row-center"
                    target="_blank"
                    href="https://x.com/iTONExchange"
                  >
                    <img src="/assets/images/landing/icon-x.svg" alt="" />
                  </a>
                  <a
                    className="icon-social row-center"
                    href="https://www.youtube.com/@iTONExchange"
                    target="_blank"
                  >
                    <img src="/assets/images/landing/icon-yt.svg" alt="" />
                  </a>
                </ListSocial>
                <div className="line"></div>
              </LineCenter>
              <h1>2024</h1>
            </BottomBlock>
          </div>
        </FooterWrap>
      </WrapParent>
    </FooterContainer>
  );
}
