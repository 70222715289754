import styled from "styled-components";

export const TitlePageV1Wrap = styled.div`
  padding: 0 20px;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  @media screen and (max-width: 768px) {
    padding: 0 10px;
  }
`;

export const TitlePageV3Wrap = styled.div`
  padding: 0 20px;
  display: flex;
  gap: 30px;
  max-width: 1440px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    padding: 0 10px;
  }
`;

export const TitlePageV1WrapTitle = styled.div`
  position: relative;
`;

export const TitlePageV1WrapCarouselWrap = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  width: calc(100% - 560px);
`;

export const TitlePageV1WrapCarousel = styled.div`
  position: relative;
  display: flex;
  width: 1250px;
`;

export const PageName = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  .img-title-page {
    display: flex;
    align-items: center;
    max-width: 22px;
  }
  h1 {
    color: #a6d9ff;
    font-family: "Rethink Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 17.36px; /* 86.8% */
    letter-spacing: 0.28px;
  }
`;

export const PageTitle = styled.div`
  margin-top: 25px;
  display: flex;
  position: relative;
  .img-coin {
    position: absolute;
    max-width: 75px;
    left: 400px;
    top: -40px;
  }
  .text-light {
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: var(--Neutral-White, #fff);
    font-family: "Rethink Sans";
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px; /* 90% */
    text-transform: uppercase;
    paint-order: stroke fill;
  }
  .text-bold {
    color: #fff;
    font-family: "Rethink Sans";
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px;
    text-transform: uppercase;
    margin-left: -10px;
  }
`;

export const DescriptionsPage = styled.div`
  margin-top: 15px;
  display: flex;
  gap: 10px;
  .img-corner {
    max-width: 10px;
  }
`;

export const DescriptionsContent = styled.div`
  max-width: 530px;
  p {
    color: rgba(255, 255, 255, 0.5);
    font-family: "Rethink Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 122.222% */
  }
`;

export const DescriptionsContentV2 = styled.div`
  max-width: 570px;
  p {
    color: rgba(255, 255, 255, 0.5);
    font-family: "Rethink Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 122.222% */
  }
  @media screen and (max-width: 1024px) {
    width: 520px;
  }
`;

export const TitlePageV2ImageRight = styled.div`
  overflow: hidden;
  width: 270px;
  height: 240px;
  position: absolute;
  right: 20px;
  top: 0;
  .img-box {
    position: absolute;
    right: 0px;
    top: 5px;
    max-width: 250px;
  }
  .img-center {
    position: absolute;
    right: 110px;
    top: 120px;
    max-width: 35px;
  }
  .img-right-corner {
    position: absolute;
    max-width: 250px;
    right: -30px;
    top: 65px;
    animation: upAndDown 3s linear infinite;
    transform: scale(0.9) rotate(10deg);
  }
`;

export const TitlePageV2ImageWrap = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

export const TitlePageV2Content = styled.div`
  position: absolute;
  top: 20px;
  right: 35px;
  h1 {
    color: #0090ff;
    font-family: "Rethink Sans";
    font-size: 19.882px;
    font-style: normal;
    font-weight: 600;
    line-height: 24.3px; /* 122.222% */
    text-transform: capitalize;
  }
  p {
    color: rgba(255, 255, 255, 0.75);
    font-family: "Rethink Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14.359px; /* 119.66% */
  }
  .content {
    margin-top: 10px;
    display: flex;
    gap: 5px;
    max-width: 220px;
    .img-corner-white {
      max-width: 10px;
      margin-top: -3px;
    }
  }
`;

export const TitlePageV1Container = styled.div`
  position: relative;
  .img-header {
    max-width: 790px;
    position: absolute;
    top: 55px;
    right: 0;
  }
  .img-corner-light {
    position: absolute;
    right: 0;
    top: -110px;
    max-width: 380px;
    fill: #73b4ff;
    mix-blend-mode: plus-lighter;
    pointer-events: none;
  }

  @media screen and (max-width: 1024px) {
    .img-header {
      width: 790px;
      right: -400px;
      top: -10px;
    }
  }

  @media screen and (max-width: 768px) {
    .img-header {
      width: 790px;
      right: -580px;
      top: -10px;
    }
    ${PageName} {
      h1 {
        font-size: 16px;
      }
    }
    ${PageTitle} {
      .text-light,
      .text-bold {
        font-size: 36px;
        line-height: 36px; /* 100% */
      }
      .text-bold {
        margin-left: -4px;
      }
    }
    ${DescriptionsPage} {
      max-width: 310px;
      p {
        font-size: 15px;
        line-height: 18px;
      }
    }
    ${DescriptionsContentV2} {
      max-width: 250px;
      p {
        font-size: 15px;
        line-height: 18px;
      }
    }
    ${TitlePageV2ImageWrap} {
      .img-box {
        max-width: 135px;
      }
      .img-right-corner {
        display: none;
      }
    }
    ${TitlePageV2Content} {
      right: 40px;
      h1 {
        color: var(--Neutral-White, #fff);
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 13.125px; /* 100.962% */
        text-transform: capitalize;
      }
      .content {
        display: none;
      }
    }
  }
`;
