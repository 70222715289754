import { useState } from "react";
import { ButtonCommonContainer, ButtonCommonWrap } from "./styled";

type ButtonCommonType = {
  text: string;
  icon?: string;
  bgColor?: string;
  width?: string;
  height?: string;
  onClick?: () => void;
  isWallet?: boolean;
};

export default function ButtonCommon({
  text,
  icon,
  width,
  onClick,
  bgColor,
  isWallet,
}: ButtonCommonType) {
  const [isShowWallet, setIsShowWallet] = useState(false);
  const onHoverEnter = () => {
    if (!isWallet) return;
    setIsShowWallet(true);
  };
  const onHoverLeave = () => {
    if (!isWallet) return;
    setIsShowWallet(false);
  };

  return (
    <ButtonCommonContainer
      style={{
        width: width ? width : "200px",
        backgroundColor: bgColor ? bgColor : "#007fff",
      }}
      onClick={onClick}
      onMouseEnter={onHoverEnter}
      onMouseLeave={onHoverLeave}
    >
      <ButtonCommonWrap>
        <div className="img-btn-left">
          <img src="/assets/images/img-btn-common.webp?v=1" alt="btn" />
          <div
            className={
              !isWallet
                ? "img-icon"
                : !isShowWallet
                ? "animate__animated animate__faster animate__fadeInRight img-icon"
                : "animate__animated animate__faster animate__fadeOutRight img-icon"
            }
          >
            <img
              src={icon ? icon : "/assets/images/icon-arrow-right.webp?v=1"}
              alt="icon"
            />
          </div>
          {isWallet && (
            <div
              className={
                isWallet && isShowWallet
                  ? "animate__animated animate__faster animate__fadeInUp img-icon"
                  : "animate__animated animate__faster animate__fadeOutDown img-icon"
              }
            >
              <img src={"/assets/images/icon-wallet.webp?v=1"} alt="icon" />
            </div>
          )}
        </div>
        <p>{text}</p>
      </ButtonCommonWrap>
    </ButtonCommonContainer>
  );
}
