import { styled } from "styled-components";
import { SelectTokenWrap } from "../SelectToken/styled";

export const WalletInfoWrap = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  background-color: #1c1c1c;
  height: 100%;
`;

export const WalletInfoTokens = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: #131313;
  ${SelectTokenWrap} {
    max-height: 422px;
  }
`;

export const WalletInfoBalance = styled.div`
  display: flex;
  width: 320px;
  padding: 9px 8px;
  flex-direction: column;
  align-items: center;
  gap: 57px;
  align-self: stretch;
  border-radius: 6px;
  background: #131313;
`;

export const WalletImage = styled.div`
  border-radius: 6px;
  background: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  position: relative;
  padding-top: 120px;
  width: 100%;
  .gif-wallet {
    max-width: 320px;
    position: absolute;
    top: 0px;
    padding-top: 20px;
    video {
      width: 100%;
    }
  }
  .img-wallet {
    max-width: 35px;
  }
  p {
    color: var(--Neutral-White, #fff);
    text-align: center;
    font-family: "Rethink Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 19.2px */
    letter-spacing: -0.32px;
  }
`;

export const WalletBalance = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  h1 {
    color: var(--www-nutsdev-com-white, var(--Neutral-White, #fff));
    text-align: center;
    font-family: "Rethink Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 24px */
  }
  p {
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    font-family: "Rethink Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
  }
`;
export const WalletActions = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
`;

export const WalletBtn = styled.div`
  width: 100%;
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: #e94a4a;
  box-shadow: 0px -2.562px 0px 0px rgba(34, 34, 34, 0.25) inset,
    0px 2.562px 0px 0px rgba(186, 186, 186, 0.25) inset;
  cursor: pointer;
  &:nth-child(2) {
    border-radius: 6px;
    background: #007fff;
    box-shadow: 0px -2.562px 0px 0px rgba(34, 34, 34, 0.25) inset,
      0px 2.562px 0px 0px rgba(186, 186, 186, 0.25) inset;
  }
  span {
    color: var(--Neutral-White, #fff);
    font-family: "Rethink Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 17.5px */
  }
`;
