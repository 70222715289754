import { styled } from "styled-components";
import { ButtonCommonContainer } from "../../Common/Button/styled";

export const BannerWrap = styled.div`
  position: relative;
  padding: 0 20px;
  padding-top: 40px;
  .img-banner {
    max-width: 215px;
    position: absolute;
    right: 120px;
    top: 100px;
    animation: rotateAround 5s linear infinite;
  }
`;

export const Title = styled.div`
  margin-bottom: 20px;
  h1 {
    color: #fff;
    font-family: "Rethink Sans";
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
    line-height: 65px;
    text-transform: uppercase;
    span {
      color: #fff;
      font-family: "Rethink Sans";
      font-size: 60px;
      font-style: italic;
      font-weight: 700;
      line-height: 65px; /* 97.5% */
      text-transform: uppercase;
    }
  }
`;

export const Contents = styled.div`
  position: relative;
  max-width: 520px;
  padding-left: 20px;
  .img-cornner {
    max-width: 12px;
    position: absolute;
    left: 0;
    top: 2px;
  }
  p {
    color: rgba(255, 255, 255, 0.5);
    font-family: "Rethink Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 144.444% */
    transition: all.3s;
  }
`;

export const ViewImage = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;
  .img-main {
    max-width: 1600px;
  }
  .img-mascot {
    position: absolute;
    top: 30px;
    left: 37%;
    z-index: 2;
    max-width: 440px;
    width: calc(100% / 3.4);
    animation: upDownAndRotate 3s linear infinite;
  }
  .img-light-blue {
    position: absolute;
    top: -500px;
    z-index: 2;
    width: 100%;
    z-index: 3;
    mix-blend-mode: plus-lighter;
    pointer-events: none;
  }
  .img-eart {
    position: absolute;
    top: 180px;
    left: 20px;
    z-index: 2;
    max-width: 210px;
    width: calc(100% / 10.4);
  }
  .img-star-down {
    position: absolute;
    top: 0;
    left: 35%;
    z-index: 2;
    max-width: 115px;
    width: calc(100% / 10.4);
  }
  .img-rocket {
    position: absolute;
    right: 20px;
    top: 0;
    z-index: 2;
    max-width: 260px;
    width: calc(100% / 9.4);
  }
  .img-star-1 {
    position: absolute;
    top: 30%;
    left: 20%;
    z-index: 2;
    max-width: 35px;
    animation: zoomInOut 1s linear infinite;
  }
  .img-star-2 {
    position: absolute;
    top: 10%;
    left: 65%;
    z-index: 2;
    max-width: 25px;
    animation: zoomInOut 2s linear infinite;
  }
  .img-star-3 {
    position: absolute;
    top: 45%;
    right: 10%;
    z-index: 2;
    max-width: 55px;
    animation: zoomInOut 1s linear infinite;
  }
  .img-left {
    position: absolute;
    left: 0px;
    max-width: 250px;
    width: calc(100% / 9.4);
    bottom: -150px;
    z-index: 5;
    animation: upAndDown 4s linear infinite;
  }
`;

export const WrapButton = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  padding-left: 20px;
  ${ButtonCommonContainer} {
    max-width: 250px;
  }
`;

export const BannerContainer = styled.div`
  position: relative;
  position: relative;
  .img-bg-header {
    max-width: 100%;
    position: absolute;
    top: -100px;
    left: 0;
    pointer-events: none;
  }
  @media screen and (max-width: 1025px) {
    .img-banner {
      max-width: 160px;
      right: 60px;
      top: 100px;
    }
    ${Title} {
      h1 {
        font-size: 40px;
        line-height: 47px;
      }
      span {
        font-size: 40px;
        line-height: 47px;
      }
    }
    ${WrapButton} {
      ${ButtonCommonContainer} {
        width: 170px !important;
      }
    }
    ${ViewImage} {
      .img-left {
        bottom: -100px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    ${BannerWrap} {
      padding: 0;
      padding-top: 40px;
      .img-banner {
        display: none;
      }
    }
    ${WrapButton} {
      width: 100%;
      top: -90px;

      .img-banner-mb {
        max-width: 70px;
        margin-right: 30px;
        animation: rotateAround 5s linear infinite;
      }
    }
    ${Title} {
      h1 {
        color: #fff;
        font-family: "Rethink Sans";
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: 35px;
        text-transform: uppercase;
        span {
          color: #fff;
          font-family: "Rethink Sans";
          font-size: 30px;
          font-style: italic;
          font-weight: 700;
          line-height: 35px; /* 111.111% */
          text-transform: uppercase;
        }
      }
    }
    ${ViewImage} {
      margin-top: 100px;
      .img-mascot {
        top: 10px;
        left: 39%;
        width: calc(100% / 4);
        animation: upDownAndRotateMB 3s linear infinite;
      }
      .img-light-blue {
        top: -170px;
      }
      .img-eart {
        top: 50px;
      }
      .img-star-1 {
        left: 15%;
      }
      .img-star-3 {
        max-width: 45px;
      }
      .img-left {
        max-width: 250px;
        width: calc(100% / 4.4);
        bottom: -80px;
      }
    }
  }
`;
