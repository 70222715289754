import { useTonAddress } from "@tonconnect/ui-react";
import {
  useGetAssetsQuery,
  useGetBalancesQuery,
} from "../../../store/api/dexApiSlice";
import {
  SelectTokenContainer,
  SelectTokenItem,
  SelectTokenList,
  SelectTokenName,
  SelectTokenWrap,
} from "../SelectToken/styled";
import {
  WalletActions,
  WalletBalance,
  WalletBtn,
  WalletImage,
  WalletInfoBalance,
  WalletInfoTokens,
  WalletInfoWrap,
} from "./styled";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBanlance } from "../../../store/features/assetsSlice";
import { instance } from "../../../services/instance";
import { Coins } from "ton3-core";
import { convertFixed } from "../../../utils/convertFormatNumber";
import { ContextProviderWrapper } from "../../Context";

type SelectTokenType = {
  onDisConnect?: any;
  onToSwap?: any;
};

export default function WalletInfo({
  onDisConnect,
  onToSwap,
}: SelectTokenType) {
  const { data: assets } = useGetAssetsQuery();
  const address = useTonAddress();
  const { data } = useGetBalancesQuery(address);
  const dispatch = useDispatch();
  const { balanceList }: any = useSelector((state: any) => state.assets);
  const [totalBalance, setTotalBalance]: any = useState();
  const { isMobile } = useContext(ContextProviderWrapper)!;

  const handleCaculateTotalDolar = async (listAsset: any) => {
    const rateResponse = await instance.post(`/rates`, {
      tokens: Object.keys(balanceList),
      currencies: ["USD"],
    });
    if (rateResponse?.status) {
      let total = 0;
      Object.keys(balanceList).map((token?: any) => {
        total =
          total +
          Number(
            Coins.fromNano(
              balanceList[token] ? balanceList[token] : 0,
              listAsset[token]?.decimals ? listAsset[token]?.decimals : 0
            )
          ) *
            rateResponse?.data?.rates[token]?.prices?.USD;
      });
      setTotalBalance(total);
    }
  };

  useEffect(() => {
    dispatch(setBanlance(data));
  }, [data]);
  useEffect(() => {
    if (!balanceList) return;
    handleCaculateTotalDolar(assets);
  }, [balanceList, assets]);

  return (
    <SelectTokenContainer>
      <WalletInfoWrap>
        <WalletInfoTokens>
          <SelectTokenWrap>
            <SelectTokenList>
              {balanceList &&
                assets &&
                Object.keys(balanceList).map((token?: any) => {
                  return (
                    assets[token]?.image_url && (
                      <SelectTokenItem key={token}>
                        <SelectTokenName>
                          <div className="img-favorite">
                            <img
                              src={
                                // @ts-ignore
                                assets[token]?.image_url
                                  ? // @ts-ignore
                                    assets[token]?.image_url
                                  : "/assets/images/icon-favorite.webp?v=1"
                              }
                              alt="favorite"
                            />
                          </div>
                          {/* @ts-ignore */}
                          <h1>{assets[token]?.symbol}</h1>
                        </SelectTokenName>
                        <p>
                          {Number(
                            Coins.fromNano(
                              balanceList[token] ? balanceList[token] : 0,
                              assets[token].decimals
                                ? assets[token].decimals
                                : 0
                            )
                          ).toFixed(2)}
                        </p>
                      </SelectTokenItem>
                    )
                  );
                })}
            </SelectTokenList>
          </SelectTokenWrap>
          {isMobile && (
            <>
              <WalletBalance
                style={{
                  margin: "20px 0",
                }}
              >
                <p>Total balance</p>
                <h1>$ {totalBalance ? Number(totalBalance).toFixed(2) : 0}</h1>
              </WalletBalance>
              <WalletActions>
                <WalletBtn onClick={onDisConnect}>
                  <span>Disconnect</span>
                </WalletBtn>
              </WalletActions>
            </>
          )}
        </WalletInfoTokens>

        {!isMobile && (
          <WalletInfoBalance>
            <WalletImage>
              <div className="gif-wallet">
                <img src="/assets/images/BuyICO/img-wallet.png" alt="img" />
              </div>
              <div className="img-wallet">
                <img src="/assets/images/icon-wallet.webp?v=1" alt="wallet" />
              </div>
              <p>Wallet is Connected</p>
            </WalletImage>
            <WalletBalance>
              <p>Total balance</p>
              <h1>$ {totalBalance ? Number(totalBalance).toFixed(2) : 0}</h1>
            </WalletBalance>
            <WalletActions>
              <WalletBtn onClick={onDisConnect}>
                <span>Disconnect</span>
              </WalletBtn>
            </WalletActions>
          </WalletInfoBalance>
        )}
      </WalletInfoWrap>
    </SelectTokenContainer>
  );
}
