import styled from "styled-components";

export const AppContainer = styled.div<{ pathname?: any }>`
  min-height: 100vh;
  background: linear-gradient(
    180deg,
    #0d0c0c 4.2%,
    #1b1f24 14.43%,
    #0b0c0e 28.98%,
    #1b1f24 39.99%,
    #0f1113 64.97%,
    #101214 100%
  );
  /* overflow: hidden; */
  &.light {
    background: linear-gradient(180deg, #fff 0%, rgba(238, 238, 240, 0) 78.94%),
      #f1f3ff;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    min-height: calc(100vh - 100px);
  }
  @media screen and (max-width: 767px) {
    min-height: 100vh;
    &.is-kibble {
      background-color: #1a1a1d;
      &.light {
        background-color: #007af5;
      }
    }
  }
`;
