import { useContext } from "react";
import { WrapParent } from "../../../Layout/Dashboard/styled";
import ButtonCommon from "../../Common/Button";
import TitleSection from "../../Common/TitleSection";
import { Contents } from "../Banner/styled";
import {
  ItemPartners,
  PartnersContainer,
  RowsPartners,
  WrapButton,
  WrapContact,
  WrapPartners,
  WrapRows,
} from "./styled";
import { ContextProviderWrapper } from "../../Context";

export default function Partners() {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  return (
    <PartnersContainer id="Partners">
      <WrapPartners>
        <WrapParent data-aos="fade-right" className="row-left ">
          <TitleSection
            pageName="How We Work"
            textLight="INVESTORS &"
            textBold="PARTNERS"
          />
        </WrapParent>
        <WrapParent data-aos="fade-left">
          <WrapRows>
            <RowsPartners className="row-center">
              {[1, 2, 3, 4].map((item: any, index: number) => (
                <ItemPartners key={index}>
                  <div className="img-partern">
                    <img
                      src={`/assets/images/landing/img-logo-${item}.webp?v=1`}
                      alt="logo"
                    />
                  </div>
                </ItemPartners>
              ))}
            </RowsPartners>
            <RowsPartners className="row-center">
              {[5, 6, 7, 8].map((item: any, index: number) => (
                <ItemPartners key={index}>
                  <div className="img-partern">
                    <img
                      src={`/assets/images/landing/img-logo-${item}.webp?v=1`}
                      alt="logo"
                    />
                  </div>
                </ItemPartners>
              ))}
            </RowsPartners>
          </WrapRows>
        </WrapParent>
        <div className="img-line-partern">
          <img
            src="/assets/images/landing/img-line-partern.webp?v=1"
            alt="partern"
          />
        </div>
      </WrapPartners>
      <WrapParent data-aos="fade-up">
        <WrapContact className="row-center">
          <div className="img-contact">
            {isMobile ? (
              <img
                src="/assets/images/landing/bg-partner-mb.webp?v=1"
                alt="contact"
              />
            ) : (
              <img
                src="/assets/images/landing/bg-contact.webp?v=1"
                alt="contact"
              />
            )}
          </div>
          <h1>
            Let’s get to <span>work</span>
          </h1>
          <Contents>
            <div className="img-cornner">
              <img src="/assets/images/icon-corner-white.webp?v=1" alt="" />
            </div>
            <p>
              We’re ready to get started on your next creative <br /> project.
              All you need to do is hit the button below
            </p>
          </Contents>
          <WrapButton>
            <ButtonCommon text="TALK TO US" />
          </WrapButton>
        </WrapContact>
      </WrapParent>
    </PartnersContainer>
  );
}
