import styled from "styled-components";

export const ButtonCommonContainer = styled.div`
  box-shadow: 0px -2.562px 0px 0px rgba(34, 34, 34, 0.25) inset,
    0px 2.562px 0px 0px rgba(186, 186, 186, 0.25) inset;
  background-color: #007fff;
  height: 44px;
  clip-path: polygon(6% 0, 94% 0, 100% 50%, 94% 99%, 6% 100%, 0% 50%);

  @media screen and (max-width: 767px) {
    clip-path: polygon(7% 0, 94% 0, 100% 50%, 94% 99%, 7% 100%, 0% 50%);
  }
`;

export const ButtonCommonWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  height: 100%;
  cursor: pointer;
  .img-btn-left {
    max-width: 52px;
    height: 100%;
    mix-blend-mode: screen;
    position: relative;
    .img-icon {
      position: absolute;
      max-width: 22px;
      top: 25%;
      left: 25%;
    }
    img {
      height: 100%;
    }
  }
  p {
    color: var(--Neutral-White, #fff);
    text-align: center;
    font-family: "Rethink Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 18px */
    margin-left: 10px;
  }
`;
