import { Navigate, useRoutes } from "react-router-dom";
import LayoutDashboard from "../Layout/Dashboard";
import LanddingPage from "../page/Landing";
import BuyICOPage from "../page/BuyICO";

export const ROUTER_PATHS = {
  SWAP: "/swap",
  LIQUIDITY: "/liquidity",
  ADD_LIQUIDITY: "/liquidity",
  FARM: "/farming",
  LAUNCH: "/launchpad",
  SHARE_POOL: "/share-pool",
  STAKING_HISTORY: "/staking-history",
  DETAIL_PROJECT: "/detail-project",
  DETAIL_FARMING: "/detail-farming",
  HOME: "/",
  BUY_ICO: "/buy-ido",
};

export const ROUTER_CONFIG = [
  {
    element: <LayoutDashboard />,
    children: [
      {
        label: "Landing",
        path: ROUTER_PATHS.HOME,
        element: <LanddingPage />,
        icon: "/assets/images/icon-swap-mb.svg",
      },
      {
        label: "Buy IDO",
        path: ROUTER_PATHS.BUY_ICO,
        element: <BuyICOPage />,
        isHiden: true,
        icon: "/assets/images/icon-swap-mb.svg",
      },
      {
        path: "*",
        element: <Navigate to={ROUTER_PATHS.HOME} replace />,
        isHiden: true,
      },
    ],
  },
];

export default function Router() {
  return useRoutes(ROUTER_CONFIG);
}
