import styled from "styled-components";
import { TableSearch } from "../../../page/Liquidity/styled";

export const ModalOverlayContainer = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  background-color: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: 0 15px;
  opacity: ${({ open }) => (open ? "1" : "0")};
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  transition: all 0.15s ease-in-out;
  z-index: 500;
`;
export const ModalOverlayWrapper = styled.div<{ width: any }>`
  position: relative;
  padding: 0 15px;
  margin: 0 auto;
  max-width: ${({ width }) => width};
  width: 100%;
  @media screen and (max-width: 767px) {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    padding: 0 10px;
    width: calc(100% - 50px);
    &.active {
      transform: translateY(-13%);
    }
    transition: all 0.3s linear;
  }
`;
export const ModalOverlayInner = styled.div`
  border-radius: 10px;
  background: #1c1c1c;
  padding: 10px;
  position: relative;
  &.light {
    border-color: #92929e;
    background: #fff;
  }
  @media screen and (max-width: 767px) {
    padding: 20px 10px;
  }
`;
export const ModalClose = styled.div`
  position: absolute;
  right: 0;
  top: -40px;
  cursor: pointer;
  .img-close {
    max-width: 40px;
  }
`;
export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  ${TableSearch} {
    width: 100%;
    input {
      padding-left: 10px;
    }
  }
`;
export const ModalTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  .img-title {
    max-width: 24px;
  }
  h1 {
    color: var(--www-nutsdev-com-white, var(--Neutral-White, #fff));
    font-family: "Rethink Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.36px; /* 96.444% */
    letter-spacing: 0.28px;
  }
`;

export const ModalMain = styled.div`
  position: relative;
  margin-top: 10px;
`;
