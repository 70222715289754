import { styled } from "styled-components";

export const TextLight = styled.h1`
  position: relative;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--Neutral-White, #fff);
  font-family: "Rethink Sans";
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  text-transform: uppercase;
  paint-order: stroke;
  margin-top: 25px;
`;

export const TextBold = styled.h1`
  position: relative;
  color: var(--www-nutsdev-com-white, #fff);
  font-family: "Rethink Sans";
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 80px;
  text-transform: uppercase;
`;

export const TitleSectionWrap = styled.div`
  position: relative;
  z-index: 5;
  @media screen and (max-width: 1025px) {
    ${TextLight} {
      font-size: 60px;
      line-height: 60px;
      margin-top: 15px;
    }
    ${TextBold} {
      font-size: 60px;
      line-height: 60px;
    }
  }
  @media screen and (max-width: 767px) {
    ${TextLight} {
      font-size: 42px;
      line-height: 42px;
      margin-top: 15px;
    }
    ${TextBold} {
      font-size: 42px;
      line-height: 42px;
    }
  }
`;
