import { useSwiper } from "swiper/react";
import { CarouselFooter } from "../../Landing/Futures/styled";
import { TableNextPage } from "../../../page/Liquidity/styled";

export const SwiperButtonNext = ({ onNext, onPre }: any) => {
  const swiper = useSwiper();
  return (
    <CarouselFooter
      style={{
        marginTop: "0",
      }}
      className="row-left"
    >
      <TableNextPage
        style={{
          backgroundImage: 'url("/assets/images/img-btn-pre.webp?v=1")',
        }}
        onClick={() => {
          onPre(swiper);
        }}
      >
        <div className="img-arrow">
          <img
            style={{
              transform: "rotate(180deg)",
            }}
            src="/assets/images/icon-arrow-right.webp?v=1"
            alt=""
          />
        </div>
      </TableNextPage>
      <TableNextPage
        style={{
          backgroundImage: 'url("/assets/images/img-btn-pre.webp?v=1")',
        }}
        onClick={() => {
          onNext(swiper);
        }}
      >
        <div className="img-arrow">
          <img src="/assets/images/icon-arrow-right.webp?v=1" alt="" />
        </div>
      </TableNextPage>
    </CarouselFooter>
  );
};
