import { styled } from "styled-components";
import ButtonCommon from "../../components/Common/Button";
import {
  ButtonCommonContainer,
  ButtonCommonWrap,
} from "../../components/Common/Button/styled";
import {
  TitlePageV2Content,
  TitlePageV2ImageWrap,
} from "../../components/Common/Title/styled";

export const LiquidityContainer = styled.div`
  position: relative;
  @media screen and (max-width: 768px) {
    ${TitlePageV2Content} {
      right: 35px;
    }
    ${TitlePageV2ImageWrap} {
      .img-center {
        max-width: 35px;
        display: block;
        top: 55px;
        right: 20%;
        z-index: 0;
      }
    }
  }
`;

export const LiquidityMain = styled.div`
  position: relative;
  @media screen and (max-width: 768px) {
    margin-bottom: 70px;
  }
`;

export const Table = styled.div`
  position: relative;
  max-width: 1440px;
  padding: 20px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  .img-ball {
    max-width: 100px;
    position: absolute;
    right: 20px;
    z-index: 2;
    img {
      border-top-right-radius: 6px;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 10px;
    .img-ball {
      max-width: 60px;
      z-index: 99;
      right: 10px;
    }
  }
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 100px;
  margin: 0px;
  margin-bottom: 15px;
  @media screen and (max-width: 768px) {
    margin: 10px 0px;
  }
`;

export const TableTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .icon-header {
    max-width: 30px;
    margin-top: 5px;
    margin-right: 5px;
  }
  .title-light {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    font-family: "Rethink Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 100% */
    text-transform: uppercase;
    paint-order: stroke fill;
  }
  .title-bold {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #0094ff;
    margin-left: 3px;
    color: #0094ff;
    font-family: "Rethink Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 100% */
    text-transform: uppercase;
    paint-order: stroke fill;
  }
  @media screen and (max-width: 768px) {
    .title-light,
    .title-bold {
      font-size: 22px;
    }
  }
`;

export const TableFilter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;
  z-index: 10;
`;

export const TableSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
  width: 382px;
  gap: 10px;
  border-radius: 8px;
  background: #101010;
  padding: 10px;
  input {
    border: none;
    outline: none;
    height: 100%;
    width: 100%;
    padding-left: 20px;
    background-color: transparent;
    color: var(--Neutral-White, #fff);
    font-family: "Rethink Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 22.5px */
  }
  .icon-search {
    max-width: 24px;
  }
`;

export const TableFiltetBtn = styled.div`
  width: 80px;
  height: 32px;
  background-color: #43424a;
  clip-path: polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0% 50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    color: #fff;
    text-align: center;
    font-family: "Rethink Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 14.4px */
    text-transform: uppercase;
  }
  .btn-right {
    max-width: 16px;
  }
  .btn-left {
    .img-unselect {
      margin-left: 15px;
      max-width: 15px;
      margin-top: -8px;
    }
  }
  .btn-right {
    .img-unselect {
      margin-left: -30px;
      max-width: 15px;
      margin-top: 2px;
    }
  }
  .btn-active-left {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 32px;
    background-color: #797979;
    clip-path: polygon(10% 0, 79% 0, 100% 50%, 78% 100%, 10% 100%, 0% 50%);
  }
  .btn-active-right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 32px;
    mix-blend-mode: plus-lighter;
    background-image: url("/assets/images/img-btn-next.webp?v=1");
    background-size: cover;
    clip-path: polygon(22% 0, 80% 0, 100% 50%, 80% 100%, 22% 100%, 0% 50%);
  }
`;

export const TableFiltetTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .icon-leaf {
    max-width: 24px;
    margin-right: 10px;
    margin-top: 3px;
  }
  p {
    color: #aaef27;
    font-family: "Rethink Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 17.36px; /* 86.8% */
    letter-spacing: 0.28px;
  }
`;

export const TableMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 10px;
`;

export const TableRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 14px 16px;
  gap: 8px;
  align-self: stretch;
  border-radius: 6px;
  background: #323232;
  p {
    color: #797979;
    font-family: "Rethink Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 18px */
    letter-spacing: -0.3px;
  }
`;

export const TableCell = styled.div`
  display: flex;
  align-items: center;
  width: 180px;
  ${ButtonCommonWrap} {
    p {
      margin-left: 10px;
    }
  }
  &:last-child {
    justify-content: center;
    align-items: center;
  }
  p {
    color: #fff;
    font-family: "Turret Road";
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; /* 21.6px */
    letter-spacing: -0.36px;
  }
  span {
    color: #fff;
    font-family: "Rethink Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    letter-spacing: -0.32px;
  }
  .img-leaf {
    max-width: 24px;
    margin-left: 10px;
  }
  .img-token-fr,
  .img-token-to {
    max-width: 30px;
  }
  .img-token-to {
    margin-left: -10px;
  }
`;

export const TableFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  p {
    color: var(--Neutral-50, #f7f7f8);
    text-align: center;
    font-family: "Turret Road";
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 125%; /* 25px */
  }
`;

export const TableFooterV2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  .img-ft {
    max-width: 30px;
  }
  p {
    color: var(--Neutral-White, #fff);
    font-family: "Rethink Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 17.5px */
  }
`;

export const TableNextPage = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 40px;
  background-color: transparent;
  clip-path: polygon(20% 0, 80% 0, 100% 50%, 80% 100%, 20% 100%, 0% 50%);
  background-position: center;
  background-size: contain;
  mix-blend-mode: plus-lighter;
  background-repeat: no-repeat;
  .img-arrow {
    max-width: 20px;
    margin-top: 3px;
  }
`;
