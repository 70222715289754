import { useContext, useEffect, useRef, useState } from "react";
import { WrapParent } from "../../../Layout/Dashboard/styled";
import TitleSection from "../../Common/TitleSection";
import { Contents } from "../Banner/styled";
import {
  MapContent,
  MapTitle,
  RoadMapContainer,
  StepMap,
  WrapSteps,
} from "./styled";
import { ContextProviderWrapper } from "../../Context";
import { Swiper, SwiperSlide } from "swiper/react";
import { SwiperButtonNext } from "../../Common/Button/BtnSwiper";
import { BenefitCarousel, BenefitCarouselWrap } from "../Benefits/styled";
import { useMotionValue, useMotionValueEvent, useScroll } from "framer-motion";

export default function RoadMap() {
  const [slideActive, setSlideActive] = useState(0);
  const [indexSticky, setIndexSticky] = useState(-1);
  const [offsetChange, setOffsetChange] = useState(0);
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const refRoadMap = useRef(null);
  const { scrollYProgress } = useScroll({
    target: refRoadMap,
    offset: ["end end", "start start"],
  });

  useMotionValueEvent(scrollYProgress, "change", (latest: any) => {
    if (isMobile) return;
    if (latest < 1) {
      setIndexSticky(1);
      setOffsetChange(latest);
    }
  });

  useEffect(() => {
    if (isMobile) return;
    if (Number(offsetChange.toFixed(2)) < 0.22) {
      setSlideActive(3);
    } else if (Number(offsetChange.toFixed(2)) < 0.52) {
      setSlideActive(2);
    } else if (Number(offsetChange.toFixed(2)) < 0.83) {
      setSlideActive(1);
    } else if (Number(offsetChange.toFixed(2)) < 1) {
      setSlideActive(0);
    }
  }, [offsetChange]);

  const onSetSwiper = (swiper: any) => {
    setSlideActive(swiper.activeIndex);
  };

  const handleNextSwiper = (swiper: any) => {
    const slideTo = slideActive === STEPS_MAP.length - 1 ? 0 : slideActive + 1;
    swiper.slideTo(slideTo);
    setSlideActive(slideTo);
  };

  const handlePreSwiper = (swiper: any) => {
    const slideTo = slideActive === 0 ? STEPS_MAP.length - 1 : slideActive - 1;
    swiper.slideTo(slideTo);
    setSlideActive(slideTo);
  };
  const onSetStepActive = (index: number) => {
    if (isMobile) return;
    setSlideActive(index);
  };
  const onRemoveStepActive = () => {
    if (isMobile) return;
    setSlideActive(-1);
  };

  return (
    <RoadMapContainer id="Roadmap" ref={refRoadMap}>
      <WrapParent
        style={{
          position:
            indexSticky === 1 && indexSticky > 0 ? "sticky" : "relative",
          top: indexSticky === 1 && indexSticky > 0 ? "120px" : "",
        }}
        data-aos="fade-right"
        className="row-left "
      >
        <TitleSection
          pageName="How We Work"
          textLight="Road Map"
          textBold="ITON"
        />
      </WrapParent>
      <WrapParent className="row-center ">
        <WrapSteps className="row-center" data-aos="fade-up">
          {isMobile ? (
            <BenefitCarouselWrap>
              <BenefitCarousel>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={20}
                  loop={true}
                  autoplay={{
                    delay: 3000,
                  }}
                  pagination={{
                    clickable: false,
                  }}
                  onSlideChange={(swiper: any) => onSetSwiper(swiper)}
                >
                  {STEPS_MAP.map((item: any, index: number) => (
                    <SwiperSlide>
                      <StepMap
                        key={index}
                        onMouseEnter={() => {
                          onSetStepActive(index);
                        }}
                        onMouseLeave={() => {
                          onRemoveStepActive();
                        }}
                        className={index === slideActive ? "active" : ""}
                      >
                        <div className="line-corner"></div>
                        <MapContent className="row-between">
                          <MapTitle className="row-left">
                            <h1>0{index + 1}</h1>
                            <h2
                              dangerouslySetInnerHTML={{ __html: item.title }}
                            ></h2>
                            {item.contents.map(
                              (content: string, jdex: number) => (
                                <Contents key={jdex}>
                                  <div className="img-cornner">
                                    <img
                                      src="/assets/images/icon-corner-white.webp?v=1"
                                      alt=""
                                    />
                                  </div>
                                  <p>{content}</p>
                                </Contents>
                              )
                            )}
                          </MapTitle>
                          <div className="img-map">
                            <img src={item.img} alt="map" />
                          </div>
                          {index === slideActive && (
                            <div className="img-light-map">
                              <img
                                src="/assets/images/landing/img-light-team.webp?v=1"
                                alt="map"
                              />
                            </div>
                          )}
                        </MapContent>
                      </StepMap>
                    </SwiperSlide>
                  ))}
                  {isMobile && (
                    <div
                      style={{
                        marginTop: "40px",
                      }}
                      className="row-right"
                    >
                      <SwiperButtonNext
                        onNext={handleNextSwiper}
                        onPre={handlePreSwiper}
                      />
                    </div>
                  )}
                </Swiper>
              </BenefitCarousel>
            </BenefitCarouselWrap>
          ) : (
            STEPS_MAP.map((item: any, index: number) => (
              <StepMap
                key={index}
                onMouseEnter={() => {
                  onSetStepActive(index);
                }}
                onMouseLeave={() => {
                  onRemoveStepActive();
                }}
                className={index === slideActive ? "active " : ""}
                style={{
                  position:
                    indexSticky === 1 && indexSticky > 0
                      ? "sticky"
                      : "relative",
                  top: indexSticky === 1 && indexSticky > 0 ? "380px" : "",
                }}
              >
                <div className="line-corner"></div>
                <MapContent className="row-between">
                  <MapTitle className="row-left">
                    <h1>0{index + 1}</h1>
                    <h2 dangerouslySetInnerHTML={{ __html: item.title }}></h2>
                    {item.contents.map((content: string, jdex: number) => (
                      <Contents key={jdex}>
                        <div className="img-cornner">
                          <img
                            src="/assets/images/icon-corner-white.webp?v=1"
                            alt=""
                          />
                        </div>
                        <p>{content}</p>
                      </Contents>
                    ))}
                  </MapTitle>
                  <div className="img-map">
                    <img src={item.img} alt="map" />
                  </div>
                  {index === slideActive && (
                    <div className="img-light-map">
                      <img
                        src="/assets/images/landing/img-light-team.webp?v=1"
                        alt="map"
                      />
                    </div>
                  )}
                </MapContent>
              </StepMap>
            ))
          )}
        </WrapSteps>
      </WrapParent>
    </RoadMapContainer>
  );
}

const STEPS_MAP = [
  {
    title: "Market Research & <br /> DPlatform Development",
    img: "/assets/images/landing/img-map-1.webp?v=1",
    contents: [
      "Researching Market",
      "Competitor Analysis",
      "Setting Up Website",
      "Building Platform",
    ],
  },
  {
    title: "DEX Aggregator <br /> Development & Launch",
    img: "/assets/images/landing/img-map-2.webp?v=1",
    contents: [
      "Laucnh DEX Aggregator",
      "Completing Marketing Strategy",
      "Onboarding Partners",
      "Researching & Developing Product",
    ],
  },
  {
    title: "Staking, Gaming & <br /> Trading Development",
    img: "/assets/images/landing/img-map-3.webp?v=1",
    contents: [
      "Establishing The Staking",
      "Publishing Games On Mini App",
      "Building Farming Feature",
      "Adding More Trading Pairs",
      "Establishing Trading Bot",
    ],
  },

  {
    title: "Upgrading Staking &<br /> Expanding Ecosystem",
    img: "/assets/images/landing/img-map-4.webp?v=1",
    contents: [
      "Upgrading for Staking and Farming",
      "Releasing Launchpad",
      "Expanding Game Ecosystem",
    ],
  },
];
